import { AUTH_PATHS } from 'Constants/paths'

import { NotFound } from 'Containers/Layout'
import {
  ChangePassword,
  ConfirmEmail,
  ForgotPassword,
  SignIn,
  SignUp,
} from 'Containers/Pages/Auth'

import { redirectAuthorized } from 'Services/Utils'

const routes = (viewer) => [
  /* Auth */
  {
    path: AUTH_PATHS.SIGN_IN,
    exact: true,
    component: redirectAuthorized(viewer)(SignIn),
  },
  {
    path: AUTH_PATHS.SIGN_UP,
    exact: true,
    component: redirectAuthorized(viewer)(SignUp),
  },
  {
    path: AUTH_PATHS.CONFIRM_EMAIL,
    exact: true,
    component: redirectAuthorized(viewer)(ConfirmEmail),
  },
  {
    path: AUTH_PATHS.FORGOT_PASSWORD,
    exact: true,
    component: ForgotPassword,
  },
  {
    path: AUTH_PATHS.CHANGE_PASSWORD,
    exact: true,
    component: redirectAuthorized(viewer)(ChangePassword),
  },
  { component: NotFound },
]

export default routes
