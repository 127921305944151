import { connect } from 'react-redux'

import { createStructuredSelector } from 'reselect'

import { searchProjects } from 'Store/Actions/admin/projects'
import { getProjects } from 'Store/Selectors/admin/projects'

import ReportProjectSelector from './ReportProjectSelector'

export default connect(createStructuredSelector({ projects: getProjects }), {
  onSearchProjects: searchProjects,
})(ReportProjectSelector)
