import { mergers } from 'rapidux'

import get from 'lodash/get'
import mergeWith from 'lodash/mergeWith'
import omit from 'lodash/omit'
// import filter from 'lodash/filter'
import pick from 'lodash/pick'

import { DELETE_EMPLOYEE_PACKAGE } from 'Store/Actions/admin/employeePackages'
import { LOG_OUT } from 'Store/Actions/viewer'

// import { DELETE_LABOR } from 'Store/Actions/admin/labors'
import handlersReducer from './handlers'

const initialState = {
  withoutImmutable: true,
}

const excludedReducers = []

export default (state = initialState, action) => {
  const data = get(action, 'payload.data')
  const ok = get(action, 'ok', false)
  const isRaw = get(action, 'isRaw', false)

  if (action.type === LOG_OUT) {
    return mergeWith(
      pick(state, excludedReducers),
      initialState,
      mergers.latestArrayMerger,
    )
  }

  if (action.type === DELETE_EMPLOYEE_PACKAGE.SUCCESS) {
    const type = get(action.payload, 'type')
    const relationId = get(action.payload, 'relationId')

    if (!type || !relationId) return state

    return mergeWith(
      {},
      state,
      {
        [type]: {
          [relationId]: {
            relationships: {
              employeePackage: {
                data: null,
              },
            },
          },
        },
      },
      mergers.latestArrayMerger,
    )
  }

  // if (action.type === DELETE_LABOR.SUCCESS) {
  //   const type = get(action.payload, 'type')
  //   const laborId = get(action.payload, 'laborId')
  //
  //   if (!type || !laborId) return state
  //   const labors = filter(state.labors, labor => labor.id !== laborId)
  //   console.log(labors)
  //   console.log(state)
  //   return state
  // }

  if (data && ok && !isRaw) {
    const nextState = mergeWith(
      {},
      state,
      omit(data, 'meta'),
      mergers.latestArrayMerger,
    )

    // Listen only for API success events
    return handlersReducer(nextState, action)
  }

  return state
}
