import styled, { css } from 'styled-components'
import { mapToTheme } from 'styled-map'
import { themeGet } from '@styled-system/theme-get'

import { Box, Flex } from 'rebass'

import { Button } from 'Components/UI'

export const Error = styled(Box)`
  color: ${themeGet('colors.danger')};
  text-align: center;
`

export const Container = styled(Box).attrs({
  m: 4,
  width: 550,
})``

export const ContentContainer = styled(Flex)`
  flex-direction: row;
  justify-content: center;
  align-self: center;
  width: 100%;
  text-align: center;
  flex-wrap: wrap;
  div {
    width: 100%;
  }
`

export const ButtonContainer = styled(Flex)`
  flex-direction: row;
  justify-content: space-around;
`

export const StyledButton = styled(Button)`
  color: ${mapToTheme('buttons.color')};
  background: ${mapToTheme('buttons.bg')};
  transition: all ${themeGet('transitionTime')} ease;
  box-shadow: ${mapToTheme('buttons.shadow')};
  ${(props) =>
    props.buttonType === 'grey' &&
    css`
      background: ${themeGet('colors.brownGray')};
    `}

  ${(props) =>
    props.buttonType === 'red' &&
    css`
      background: ${themeGet('colors.salmon')};
    `}
`
