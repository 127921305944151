import identity from 'lodash/identity'
import merge from 'lodash/merge'
import pickBy from 'lodash/pickBy'

import apiCall from 'Services/Api'

import { createAsyncAction } from 'Store/utils'

export const LOAD_CLIENTS = createAsyncAction('admin/clients/LOAD')
export const SEARCH_CLIENTS = createAsyncAction('admin/clients/SEARCH_CLIENTS')
export const CREATE_CLIENT = createAsyncAction('admin/clients/CREATE')
export const ACTIVATE_CLIENT = createAsyncAction('admin/clients/ACTIVATE')
export const DEACTIVATE_CLIENT = createAsyncAction('admin/clients/DEACTIVATE')

const clientIncludes = []

export const loadClients = ({
  paged = false,
  number = 1,
  size = 10,
  sort,
  filters,
} = {}) => (dispatch, getState) => {
  const params = {
    endpoint: '/admin/clients',
    types: LOAD_CLIENTS,
    query: {
      include: clientIncludes.join(),
    },
  }

  if (paged) {
    merge(params, {
      paged,
      query: pickBy(
        {
          include: clientIncludes.join(),
          page: {
            number,
            size,
          },
          filter: filters,
          sort,
        },
        identity,
      ),
      payload: {
        paged: {
          number,
          size,
        },
      },
    })
  }

  return apiCall(params)(dispatch, getState)
}

export const searchClients = (searchQuery, filters) =>
  apiCall({
    endpoint: '/admin/clients',
    types: SEARCH_CLIENTS,
    query: {
      filter: {
        ...filters,
        name: {
          ilike: `%${searchQuery}%`,
        },
      },
      page: {
        number: 1,
        size: 20,
      },
    },
  })

export const createClient = ({ name, email, phoneNumber, notes }) =>
  apiCall({
    method: 'POST',
    endpoint: '/admin/clients',
    types: CREATE_CLIENT,
    query: {
      include: clientIncludes.join(),
      data: {
        type: 'clients',
        attributes: pickBy({ name, email, phoneNumber, notes }, identity),
      },
    },
  })

export const activateClient = (clientId) =>
  apiCall({
    method: 'POST',
    endpoint: `/admin/clients/${clientId}/activation`,
    types: ACTIVATE_CLIENT,
    query: {
      include: clientIncludes.join(),
    },
  })

export const deactivateClient = (clientId) =>
  apiCall({
    method: 'DELETE',
    endpoint: `/admin/clients/${clientId}/activation`,
    types: DEACTIVATE_CLIENT,
    query: {
      include: clientIncludes.join(),
    },
  })
