import { connect } from 'react-redux'

import { deleteEmployeePackage } from 'Store/Actions/admin/employeePackages'
import { uploadFile } from 'Store/Actions/uploading'

import EmployeePackageUploader from './EmployeePackageUploader'

export default connect(null, {
  onUploadFile: uploadFile,
  onDelete: deleteEmployeePackage,
})(EmployeePackageUploader)
