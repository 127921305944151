import { connect } from 'react-redux'

import { compose, withProps } from 'recompose'
import { createStructuredSelector } from 'reselect'

import get from 'lodash/get'

import { searchLabors } from 'Store/Actions/admin/labors'
import { loadRoles } from 'Store/Actions/admin/roles'
import { getRoles } from 'Store/Selectors/admin/roles'

import RolesSelector from './RolesSelector'

export default compose(
  connect(
    createStructuredSelector({
      roles: getRoles,
    }),
    {
      onLoadRoles: loadRoles,
      onSearchLabors: searchLabors,
    },
  ),
  withProps((props) => {
    const kindValue = get(props, 'kind.value')

    return {
      onLoadRoles: () =>
        !props.disabled && kindValue
          ? props.onLoadRoles({
              filters: { kind: { eq: kindValue } },
            })
          : undefined,
    }
  }),
)(RolesSelector)
