import get from 'lodash/get'
import identity from 'lodash/identity'
import merge from 'lodash/merge'
import pickBy from 'lodash/pickBy'

import apiCall from 'Services/Api'
import { transformToRequest } from 'Services/Entities/SubcontractorProviders'

import { getSubcontractorProvider } from 'Store/Selectors/admin/subcontractor-providers'
import { createAsyncAction } from 'Store/utils'

import { laborIncludes } from './labors'

export const LOAD_SUBCONTRACTOR_PROVIDERS = createAsyncAction(
  'admin/subcontractorProviders/LOAD',
)
export const LOAD_SUBCONTRACTOR_PROVIDER = createAsyncAction(
  'admin/subcontractorProvider/LOAD',
)
export const SEARCH_SUBCONTRACTOR_PROVIDERS = createAsyncAction(
  'admin/subcontractorProviders/SEARCH',
)
export const CREATE_SUBCONTRACTOR_PROVIDER = createAsyncAction(
  'admin/subcontractorProviders/CREATE',
)
export const EDIT_SUBCONTRACTOR_PROVIDER = createAsyncAction(
  'admin/subcontractorProviders/EDIT',
)
export const ACTIVATE_SUBCONTRACTOR_PROVIDER = createAsyncAction(
  'admin/subcontractorProviders/ACTIVATE',
)
export const DEACTIVATE_SUBCONTRACTOR_PROVIDER = createAsyncAction(
  'admin/subcontractorProviders/DEACTIVATE',
)
export const LOAD_SUBCONTRACTOR_PROVIDER_LABORS = createAsyncAction(
  'admin/subcontractorProviders/LOAD_SUBCONTRACTOR_PROVIDER_LABORS',
)

const subcontractorProviderIncludes = []

export const loadSubcontractorProviders = ({
  paged = false,
  number = 1,
  size = 10,
  sort,
  filters,
} = {}) => (dispatch, getState) => {
  const params = {
    endpoint: '/admin/subcontractor_providers',
    types: LOAD_SUBCONTRACTOR_PROVIDERS,
    query: {
      include: subcontractorProviderIncludes.join(),
    },
  }

  if (paged) {
    merge(params, {
      paged,
      query: pickBy(
        {
          include: subcontractorProviderIncludes.join(),
          page: {
            number,
            size,
          },
          filter: filters,
          sort,
        },
        identity,
      ),
      payload: {
        paged: {
          number,
          size,
        },
      },
    })
  }

  return apiCall(params)(dispatch, getState)
}

export const loadSubcontractorProvider = (subcontractorProviderId) =>
  apiCall({
    endpoint: `/admin/subcontractor_providers/${subcontractorProviderId}`,
    types: LOAD_SUBCONTRACTOR_PROVIDER,
    query: {
      include: subcontractorProviderIncludes.join(),
    },
  })

export const searchSubcontractorProviders = (searchQuery, filters) =>
  apiCall({
    endpoint: '/admin/subcontractor_providers',
    types: SEARCH_SUBCONTRACTOR_PROVIDERS,
    query: {
      filter: merge(filters, {
        name: {
          ilike: `%${searchQuery}%`,
        },
      }),
      page: {
        number: 1,
        size: 20,
      },
    },
  })

export const createSubcontractorProvider = (values) =>
  apiCall({
    method: 'POST',
    endpoint: '/admin/subcontractor_providers',
    types: CREATE_SUBCONTRACTOR_PROVIDER,
    query: {
      include: subcontractorProviderIncludes.join(),
      data: {
        type: 'subcontractorProviders',
        attributes: transformToRequest(values),
      },
    },
  })

export const editSubcontractorProvider = ({
  subcontractorProviderId,
  ...values
}) =>
  apiCall({
    method: 'PATCH',
    endpoint: `/admin/subcontractor_providers/${subcontractorProviderId}`,
    types: EDIT_SUBCONTRACTOR_PROVIDER,
    query: {
      include: subcontractorProviderIncludes.join(),
      data: {
        type: 'subcontractorProviders',
        attributes: transformToRequest(values),
      },
    },
  })

export const activateSubcontractorProvider = (id) =>
  apiCall({
    method: 'POST',
    endpoint: `/admin/subcontractor_providers/${id}/activation`,
    types: ACTIVATE_SUBCONTRACTOR_PROVIDER,
    query: {
      include: subcontractorProviderIncludes.join(),
    },
  })

export const deactivateSubcontractorProvider = (id) =>
  apiCall({
    method: 'DELETE',
    endpoint: `/admin/subcontractor_providers/${id}/activation`,
    types: DEACTIVATE_SUBCONTRACTOR_PROVIDER,
    query: {
      include: subcontractorProviderIncludes.join(),
    },
  })

export const loadSubcontractorProviderLabors = ({
  paged = false,
  number = 1,
  size = 10,
  sort,
  filters,
} = {}) => (dispatch, getState) => {
  const subcontractorProvider = getSubcontractorProvider(getState())

  const params = {
    endpoint: '/admin/labors',
    types: LOAD_SUBCONTRACTOR_PROVIDER_LABORS,
    query: {
      include: laborIncludes.join(),
      filter: {
        subcontractorProviderId: {
          eq: get(subcontractorProvider, 'entity.id'),
        },
      },
    },
  }

  if (paged) {
    merge(params, {
      paged,
      query: pickBy(
        {
          include: laborIncludes.join(),
          page: {
            number,
            size,
          },
          filter: filters,
          sort,
        },
        identity,
      ),
      payload: {
        paged: {
          number,
          size,
        },
      },
    })
  }

  return apiCall(params)(dispatch, getState)
}
