import { createFields, createLoadHandler, createReducerHandlers } from 'rapidux'

import { CREATE_ROLE, LOAD_ROLES } from 'Store/Actions/admin/roles'
import { LOG_OUT } from 'Store/Actions/viewer'
import { createReducer } from 'Store/utils'

const initialState = {
  ...createFields('roles'),
}
const handlers = {
  ...createReducerHandlers('roles', LOAD_ROLES, {
    withReplace: true,
  }),

  [CREATE_ROLE.SUCCESS]: createLoadHandler('roles'),

  [LOG_OUT]: (state) => state.merge(initialState),
}

export default createReducer(initialState, handlers)
