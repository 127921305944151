import React, { PureComponent, Suspense } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { renderRoutes } from 'react-router-config'
import PropTypes from 'prop-types'

import { compose } from 'recompose'

import { Header, Sidebar } from 'Components/Blocks/App'
import { Loader } from 'Components/UI'

import { withAppContext } from 'Services/Context'

import { AppContainer, AppContent } from './styles'

class Platform extends PureComponent {
  componentDidMount() {
    window.scrollTo(0, 0)
  }

  render() {
    const { route } = this.props

    return (
      <AppContainer>
        <Sidebar />
        <Header />
        <AppContent>
          <Suspense fallback={<Loader />}>
            {renderRoutes(route.routes)}
          </Suspense>
        </AppContent>
      </AppContainer>
    )
  }
}

Platform.defaultProps = {}

Platform.propTypes = {
  route: PropTypes.object.isRequired,
}

export default compose(withAppContext, withRouter, connect())(Platform)
