import React, { Fragment, PureComponent } from 'react'
import { Form as FinalForm } from 'react-final-form'
import { toast } from 'react-toastify'
import PropTypes from 'prop-types'

import get from 'lodash/get'

import { loadingGlyph } from 'Assets/Svg'

import { Toast } from 'Components/Blocks'
import { DayPicker } from 'Components/Blocks/Forms'
import { Button, Icon, Modal, Text, Title } from 'Components/UI'

class ProjectStatusModal extends PureComponent {
  state = { isLoading: false, error: null }

  get isActive() {
    const { project } = this.props

    return get(project, 'state') === 'active'
  }

  handleCallback = () => {
    const { onCallback } = this.props

    this.setState({ error: null, isLoading: false })
    onCallback()
  }

  handleSubmit = async () => {
    const { project, onActivateProject, onDeactivateProject } = this.props
    const { isActive } = this

    this.setState({ isLoading: true, error: null })

    const { ok, error } = isActive
      ? await onDeactivateProject(project.id)
      : await onActivateProject(project.id)

    if (ok) {
      toast.success(
        <Toast
          heading={`Project ${isActive ? 'deactivated' : 'activated'}`}
          type="success"
        />,
      )

      this.handleCallback()
    } else if (get(error, 'status') === 422) {
      this.setState({
        error: `Project cannot be ${isActive ? 'deactivated' : 'activated'}`,
      })
    } else {
      this.setState({ error: 'Something goes wrong' })
    }

    this.setState({ isLoading: false })
  }

  renderForm = ({ handleSubmit }) => {
    const { project } = this.props
    const { isLoading, error } = this.state

    return (
      <>
        <Title.H2 color="brownishGray" mb={4} mt={5} textAlign="center">
          {`${!this.isActive ? 'Activating' : 'Deactivating'} ${get(
            project,
            'name',
            '',
          )}`}
        </Title.H2>

        <DayPicker label="Effective Date" name="effectiveDate" />

        {error && <Text color="danger">{error}</Text>}

        <Button
          disabled={isLoading}
          mt={4}
          secondary
          width={1}
          onClick={handleSubmit}
        >
          {isLoading && <Icon glyph={loadingGlyph} size={28} />}

          {!this.isActive ? 'Activate' : 'Deactivate'}
        </Button>
      </>
    )
  }

  render = () => {
    const { project } = this.props

    return (
      <Modal
        isOpen={!!project}
        isOverflow={false}
        onCallback={this.handleCallback}
      >
        <Modal.Close onClick={this.handleCallback} />

        <FinalForm render={this.renderForm} onSubmit={this.handleSubmit} />
      </Modal>
    )
  }
}

ProjectStatusModal.defaultProps = { project: null }
ProjectStatusModal.propTypes = {
  project: PropTypes.object,
  onActivateProject: PropTypes.func.isRequired,
  onCallback: PropTypes.func.isRequired,
  onDeactivateProject: PropTypes.func.isRequired,
}

export default ProjectStatusModal
