import React from 'react'
import PropTypes from 'prop-types'

import { logoGlyph } from 'Assets/Svg'
import {
  menuAdminGlyph,
  menuClientsGlyph,
  menuProjectsGlyph,
  menuReportsGlyph,
  menuRolesGlyph,
  menuSubcontractorProvidersGlyph,
  menuSupervisorsGlyph,
} from 'Assets/Svg/Menu'

import { Icon } from 'Components/UI'

import { ADMIN_PATHS, ADMIN_ROOT, ROOT_PATH } from 'Constants/paths'
import { HUMAN_USER_TYPES } from 'Constants/types'

import { Can } from 'Services/Ability'

import { Bar, Bottom, Container, Label, Menu, StyledNav, Top } from './styles'

const Sidebar = ({ viewer, shrinked }) => (
  <Container shrinked={shrinked}>
    <Bar shrinked={shrinked}>
      <Menu>
        <Top>
          <StyledNav exact href={ROOT_PATH} logo="true" to={ADMIN_ROOT}>
            <Icon glyph={logoGlyph} size={40} />
            {HUMAN_USER_TYPES[viewer.type]} Panel
          </StyledNav>
          {/* <StyledNav
            exact
            href={ADMIN_PATHS.DASHBOARD}
            to={ADMIN_PATHS.DASHBOARD}
          >
            <Icon glyph={menuAdminGlyph} size={24} />
            <Label>Dashboard</Label>
          </StyledNav> */}
          <Can do="read" on="projects">
            <StyledNav href={ADMIN_PATHS.PROJECTS} to={ADMIN_PATHS.PROJECTS}>
              <Icon glyph={menuProjectsGlyph} size={24} />
              <Label>Projects</Label>
            </StyledNav>
          </Can>
          <Can do="read" on="admins">
            <StyledNav href={ADMIN_PATHS.ADMINS} to={ADMIN_PATHS.ADMINS}>
              <Icon glyph={menuAdminGlyph} size={24} />
              <Label>Admins</Label>
            </StyledNav>
          </Can>
          <Can do="read" on="labors">
            <StyledNav href={ADMIN_PATHS.LABOR} to={ADMIN_PATHS.LABOR}>
              <Icon glyph={menuSupervisorsGlyph} size={24} />
              <Label>Labor</Label>
            </StyledNav>
          </Can>
          <Can do="read" on="reports">
            <StyledNav href={ADMIN_PATHS.REPORTS} to={ADMIN_PATHS.REPORTS}>
              <Icon fill="dodgerBlue" glyph={menuReportsGlyph} size={24} />
              <Label>Reports</Label>
            </StyledNav>
          </Can>
          <Can do="read" on="invitations">
            <StyledNav
              href={ADMIN_PATHS.INVITATIONS}
              to={ADMIN_PATHS.INVITATIONS}
            >
              <Icon glyph={menuProjectsGlyph} size={24} />
              <Label>Invitations</Label>
            </StyledNav>
          </Can>
          <Can do="read" on="clients">
            <StyledNav href={ADMIN_PATHS.CLIENTS} to={ADMIN_PATHS.CLIENTS}>
              <Icon fill="dodgerBlue" glyph={menuClientsGlyph} size={24} />
              <Label>Clients</Label>
            </StyledNav>
          </Can>
          <Can do="read" on="subcontractors">
            <StyledNav
              href={ADMIN_PATHS.SUBCONTRACTOR_PROVIDERS}
              to={ADMIN_PATHS.SUBCONTRACTOR_PROVIDERS}
            >
              <Icon
                fill="dodgerBlue"
                glyph={menuSubcontractorProvidersGlyph}
                size={24}
              />
              <Label>Sub-Contractor Company Providers</Label>
            </StyledNav>
          </Can>
          {viewer.type === 'superAdmins' && (
            <Can do="read" on="roles">
              <StyledNav href={ADMIN_PATHS.ROLES} to={ADMIN_PATHS.ROLES}>
                <Icon fill="dodgerBlue" glyph={menuRolesGlyph} size={24} />
                <Label>Roles</Label>
              </StyledNav>
            </Can>
          )}
        </Top>
        <Bottom />
      </Menu>
    </Bar>
  </Container>
)

Sidebar.propTypes = {
  shrinked: PropTypes.bool.isRequired,
  viewer: PropTypes.object.isRequired,
}

export default Sidebar
