import React, { PureComponent } from 'react'
import { Form } from 'react-final-form'
import { toast } from 'react-toastify'
import PropTypes from 'prop-types'

import validate from 'validate.js'

import get from 'lodash/get'

import { Forms, Toast } from 'Components/Blocks'
import { Button, Modal, Title } from 'Components/UI'

import {
  emailConstraint,
  phoneNumberConstraint,
  presenceFieldConstraint,
} from 'Constants/constraints'
import { PHONE_MASK } from 'Constants/masks'
import { ADMIN_PATHS } from 'Constants/paths'
import { ADMIN_FORM_USER_TYPES, HUMAN_USER_TYPES } from 'Constants/types'

import { addUSCode, getCreatedDataId } from 'Services/Utils'

import { Error } from './styles'

import { FormContainer } from '../styles'

class NewAdmin extends PureComponent {
  state = {
    error: null,
  }

  validate = (values) =>
    validate(values, {
      ...emailConstraint('email', true),
      ...presenceFieldConstraint('firstName'),
      ...presenceFieldConstraint('lastName'),
      ...phoneNumberConstraint('phoneNumber', true),
      ...presenceFieldConstraint('type'),
    })

  handleCreateAdmin = async (values) => {
    const {
      onInviteAdmin,
      onCallback,
      history: { push },
    } = this.props

    this.setState({ error: null })

    const data = {
      ...values,
      phoneNumber: addUSCode(values.phoneNumber),
      kind: values.type.value,
    }
    const isInvite = get(values, 'email')

    const result = await onInviteAdmin(data)

    if (result.ok) {
      toast.success(
        <Toast
          heading={`Admin ${isInvite ? 'invited' : 'created'}`}
          type="success"
        />,
      )
      onCallback()

      if (!isInvite) {
        push(ADMIN_PATHS.ADMIN(getCreatedDataId(result, 'admins')))
      }
    } else if (get(result, 'error.status') === 409) {
      this.setState({ error: 'User already exists' })
    } else {
      this.setState({
        error: 'Something goes wrong. Please try again later...',
      })
    }
  }

  handleCallback = () => {
    const { onCallback } = this.props
    this.setState({ error: null })
    onCallback()
  }

  renderForm = ({ handleSubmit, invalid }) => {
    const { error } = this.state

    return (
      <FormContainer onSubmit={handleSubmit}>
        <Title.H2 color="brownishGray" mb={4} mt={1} textAlign="center">
          Add New Admin
        </Title.H2>

        <Forms.Input
          label="First Name"
          name="firstName"
          placeholder="Enter Admin's First Name"
        />
        <Forms.Input
          label="Last Name"
          name="lastName"
          placeholder="Ented Admin's Last Name"
        />
        <Forms.Input
          label="Email"
          name="email"
          placeholder="Enter Admin's Email"
        />
        <Forms.Input
          label="Phone Number"
          mask={PHONE_MASK}
          name="phoneNumber"
          placeholder="Enter Admin's Phone"
        />
        <Forms.Select
          label="Type*"
          name="type"
          options={[
            {
              value: ADMIN_FORM_USER_TYPES.admin,
              label: HUMAN_USER_TYPES.admins,
            },
            {
              value: ADMIN_FORM_USER_TYPES.onboardingAdmins,
              label: HUMAN_USER_TYPES.onboardingAdmins,
            },
          ]}
          placeholder="Choose Admin Type"
          selectProps={{
            isSearchable: false,
          }}
        />

        <Button disabled={invalid} mt={4} secondary type="submit" width={1}>
          Add Admin
        </Button>

        {error && <Error>{error}</Error>}
      </FormContainer>
    )
  }

  render() {
    const { isOpen } = this.props

    return (
      <Modal isOpen={isOpen} onCallback={this.handleCallback}>
        <Modal.Close onClick={this.handleCallback} />
        <Form
          render={this.renderForm}
          validate={this.validate}
          onSubmit={this.handleCreateAdmin}
        />
      </Modal>
    )
  }
}

NewAdmin.propTypes = {
  history: PropTypes.object.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onCallback: PropTypes.func.isRequired,
  onInviteAdmin: PropTypes.func.isRequired,
}

export default NewAdmin
