import { DateTime } from 'luxon'

import find from 'lodash/find'
import forEach from 'lodash/forEach'
import get from 'lodash/get'
import has from 'lodash/has'
import identity from 'lodash/identity'
import isEqual from 'lodash/isEqual'
import map from 'lodash/map'
import pick from 'lodash/pick'
import pickBy from 'lodash/pickBy'
import reduce from 'lodash/reduce'

import {
  PROJECT_KIND_LABELS,
  PROJECT_OT_RULE_LABELS,
  PROJECT_SIGNATURE_LABELS,
  PROJECT_WEEKEND_LABELS,
} from 'Constants/types'

import {
  addUSCode,
  getTimezoneLabelWithOffset,
  removeUSCode,
} from 'Services/Utils'

export const transformToRequest = (projectValues) => ({
  type: 'projects',
  attributes: {
    ...pickBy(
      {
        ...pick(projectValues, [
          'name',
          'clientPmFirstName',
          'clientPmLastName',
          'clientPmEmail',
          'clientProjectNumber',
          'internalProjectNumber',
          'startDate',
        ]),
        location:
          get(projectValues, 'location.label') ||
          get(projectValues, 'location'),
        kind: get(projectValues, 'kind.value'),
        otRule: get(projectValues, 'otRule.value'),
        projectTimeZone: get(projectValues, 'projectTimeZone.value'),
        signatureType: get(projectValues, 'signatureType.value'),
        clientId: get(projectValues, 'client.value'),
        clientWeekEndingDay: get(projectValues, 'clientWeekEndingDay.value'),
        clientPmPhone: addUSCode(get(projectValues, 'clientPmPhone')),
        projectUserRoleAttributes: reduce(
          get(projectValues, 'roles'),
          (acc, role) => {
            forEach(role.labors, (labor) => {
              acc.push({
                user_id: labor.value,
                role_id: role.value,
              })
            })
            return acc
          },
          [],
        ),
      },
      identity,
    ),
    latitude: get(projectValues, 'location.location.lat', null),
    longitude: get(projectValues, 'location.location.lng', null),
    endDate: get(projectValues, 'endDate', null),
  },
  relationships: pickBy(
    {
      costCodes: has(projectValues, 'costCodes')
        ? {
            data: map(get(projectValues, 'costCodes'), (costCode) => ({
              type: 'cost_codes',
              id: costCode.value,
            })),
          }
        : undefined,
      roles: has(projectValues, 'roles')
        ? {
            data: map(get(projectValues, 'roles'), (roles) => ({
              type: 'roles',
              id: roles.value,
            })),
          }
        : undefined,
    },
    identity,
  ),
})

export const transformToForm = (project) => ({
  ...pick(project, [
    'name',
    'clientProjectNumber',
    'internalProjectNumber',
    'clientPmFirstName',
    'clientPmLastName',
    'clientPmEmail',
  ]),
  location:
    get(project, 'latitude') && get(project, 'longitude')
      ? {
          label: get(project, 'location'),
          location: {
            lat: get(project, 'latitude'),
            lng: get(project, 'longitude'),
          },
        }
      : get(project, 'location'),
  clientPmPhone: removeUSCode(project.clientPmPhone),
  kind: { value: project.kind, label: PROJECT_KIND_LABELS[project.kind] },
  startDate: DateTime.fromISO(get(project, 'startDate')).toJSDate(),
  endDate: DateTime.fromISO(get(project, 'endDate')).invalid
    ? undefined
    : DateTime.fromISO(get(project, 'endDate')).toJSDate(),
  projectTimeZone: {
    value: project.projectTimeZone,
    label: getTimezoneLabelWithOffset(project.projectTimeZone),
  },
  otRule: {
    value: project.otRule,
    label: PROJECT_OT_RULE_LABELS[project.otRule],
  },
  signatureType: {
    value: project.signatureType,
    label: PROJECT_SIGNATURE_LABELS[project.signatureType],
  },
  client: {
    value: get(project, 'client.id'),
    label: get(project, 'client.name'),
  },
  clientWeekEndingDay: {
    value: project.clientWeekEndingDay,
    label: PROJECT_WEEKEND_LABELS[project.clientWeekEndingDay],
  },
  costCodes: map(project.costCodes, (code) => ({
    value: code.id,
    label: code.code,
  })),
  roles: Object.values(
    reduce(
      project.projectUserRoles,
      (acc, userRole) => {
        if (!acc[userRole.role.id]) {
          const name = isEqual(userRole.role.name, 'Supervisor')
            ? 'Field Supervisor'
            : userRole.role.name

          acc[userRole.role.id] = {
            id: userRole.role.id,
            name,
            value: userRole.role.id,
            label: name,
            labors: [],
          }
        }

        acc[userRole.role.id].labors.push({
          id: userRole.user.id,
          image: get(
            userRole,
            'user.profile.personalPhoto.content.smallThumb.url',
          ),
          value: userRole.user.id,
          label: `${get(userRole, 'user.profile.firstName')} ${get(
            userRole,
            'user.profile.lastName',
          )}`,
        })

        return acc
      },
      {},
    ),
  ),
  permissions: Object.values(
    reduce(
      project.projectUserRoles,
      (acc, userRole) => {
        const permissions = get(userRole, 'permissions')
        const superVisor = find(
          permissions,
          (permission) => permission.id === 'superviseProject',
        )

        if (superVisor) {
          acc.push({
            id: userRole.user.id,
            image: get(
              userRole,
              'user.profile.personalPhoto.content.smallThumb.url',
            ),
            value: userRole.user.id,
            label: `${get(userRole, 'user.profile.firstName')} ${get(
              userRole,
              'user.profile.lastName',
            )}`,
            permission: 'superviseProject',
          })
        }

        return acc
      },
      [],
    ),
  ),
})

export const getLaborsIds = (roles) =>
  reduce(
    roles,
    (acc, role) => acc.concat(map(get(role, 'labors'), (labor) => labor.id)),
    [],
  )
