import apiCall from 'Services/Api'

import { createAsyncAction } from 'Store/utils'

export const LOAD_US_STATES = createAsyncAction(
  'onboarding/basic/LOAD_US_STATES',
)

export const loadUsStates = () =>
  apiCall({
    endpoint: '/us_states',
    types: LOAD_US_STATES,
  })
