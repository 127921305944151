import { LOAD_ADMINS } from 'Store/Actions/admin/admins'
import { LOAD_INVITATIONS } from 'Store/Actions/admin/invitations'
import {
  CHANGE_PAGE,
  CHANGE_PAGE_SIZE,
  CHANGE_SORTED,
  TOGGLE_SIDEBAR,
} from 'Store/Actions/ui'
import { createReducer } from 'Store/utils'

const initialState = {
  sidebarShrinked: true,

  adminsPaged: {
    size: 10,
    number: 1,
  },
  adminsSorted: {
    id: 'createdAt',
  },

  laborsPaged: {
    size: 10,
    number: 1,
  },
  laborsSorted: {
    id: 'createdAt',
  },

  invitationsPaged: {
    size: 10,
    number: 1,
  },

  projectsPaged: {
    size: 10,
    number: 1,
  },
  projectsSorted: {
    id: 'name',
  },

  checkinsPaged: {
    size: 10,
    number: 1,
  },

  clientsPaged: {
    size: 10,
    number: 1,
  },

  subcontractorProvidersPaged: {
    size: 10,
    number: 1,
  },

  subcontractorProviderLaborsPaged: {
    size: 10,
    number: 1,
  },
}
const handlers = {
  [TOGGLE_SIDEBAR]: (state) =>
    state.merge({ sidebarShrinked: !state.sidebarShrinked }),

  [CHANGE_PAGE]: (state, { payload: { type, page } }) =>
    state.setIn([`${type}Paged`, 'number'], page),

  [CHANGE_PAGE_SIZE]: (state, { payload: { type, size } }) =>
    state.setIn([`${type}Paged`, 'size'], size),

  [CHANGE_SORTED]: (state, { payload: { type, data } }) =>
    state.merge({ [`${type}Sorted`]: data }),

  [LOAD_ADMINS.SUCCESS]: (state, { payload: { paged } }) =>
    state.merge({ adminsPaged: paged }),

  [LOAD_INVITATIONS.SUCCESS]: (state, { payload: { paged } }) =>
    state.merge({ invitationsPaged: paged }),
}

export default createReducer(initialState, handlers)
