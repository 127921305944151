import { connect } from 'react-redux'

import { createRole, updateRole } from 'Store/Actions/admin/roles'

import CreateRole from './CreateRole'

export default connect(null, {
  onCreateRole: createRole,
  onUpdateRole: updateRole,
})(CreateRole)
