import React, { PureComponent } from 'react'
import { toast } from 'react-toastify'
import PropTypes from 'prop-types'

import { Flex } from 'rebass'

import { Forms, Toast } from 'Components/Blocks'
import { Button, Text, Title } from 'Components/UI'

class PhoneVerification extends PureComponent {
  handleResendSms = async () => {
    const { onSendVerificationCode, userId, phoneNumberId } = this.props

    const { ok, error } = await onSendVerificationCode({
      laborId: userId,
      phoneNumberId,
    })

    if (ok) {
      toast.success(
        <Toast heading="Verification code sent to your phone" type="success" />,
      )
    }

    if (error) {
      toast.error(
        <Toast
          heading="Something goes wrong"
          text={error.message}
          type="error"
        />,
      )
    }
  }

  render = () => (
    <>
      <Title.H2 mb={5} textAlign="center">
        Phone verification
      </Title.H2>

      <Text color="brownishGray" mb={4} textAlign="center" width={1}>
        Enter the verification code sent to your phone via SMS
      </Text>

      <Flex flexDirection="column" justifyContent="center" width={1}>
        <Forms.Input label="Verification code" name="verificationCode" />

        <Button mt={3} shadow={false} white onClick={this.handleResendSms}>
          Resend SMS code
        </Button>
      </Flex>
    </>
  )
}

PhoneVerification.propTypes = {
  phoneNumberId: PropTypes.string.isRequired,
  userId: PropTypes.string.isRequired,
  onSendVerificationCode: PropTypes.func.isRequired,
}

export default PhoneVerification
