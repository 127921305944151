import { connectRouter } from 'connected-react-router'
import { combineReducers } from 'redux'

import admin from './admin'
import app from './app'
import auth from './auth'
import data from './data'
import onboarding from './onboarding'
import persist from './persist'
import tables from './tables'
import ui from './ui'
import viewer from './viewer'

export default (history) =>
  combineReducers({
    app,
    admin,
    auth,
    persist,
    viewer,
    router: connectRouter(history),
    data,
    onboarding,
    ui,
    tables,
  })
