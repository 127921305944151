import React, { PureComponent } from 'react'
import { Field } from 'react-final-form'
import PropTypes from 'prop-types'

import debounce from 'debounce-promise'
import { denormalize } from 'rapidux'

import get from 'lodash/get'
import map from 'lodash/map'
import reduce from 'lodash/reduce'

import { Select } from 'Components/Blocks/Forms'

const entityToOption = (entity) => ({
  id: get(entity, 'id'),
  value: get(entity, 'id'),
  label: get(entity, 'name'),
  kind: get(entity, 'kind'),
  weekEndingDay: get(entity, 'clientWeekEndingDay'),
  costCodes: get(entity, 'costCodes'),
})

class FieldRenderer extends PureComponent {
  loadOptions = debounce(async (inputValue) => {
    const { onSearchProjects } = this.props

    const {
      payload: { data },
    } = await onSearchProjects(inputValue)

    const options = denormalize(data, 'projects') || []

    return map(options, entityToOption)
  }, 500)

  get projects() {
    const { projects } = this.props
    return reduce(
      projects.entities,
      (acc, project) => {
        acc[project.id] = project
        return acc
      },
      {},
    )
  }

  render = () => {
    const { input, projects, selectProps, subtitle } = this.props

    return (
      <Select
        components={{
          ClearIndicator: Select.emptyComponent,
        }}
        label="Project"
        name={input.name}
        options={map(this.projects, entityToOption)}
        placeholder="Search by Project Name"
        selectProps={{
          async: true,
          loadOptions: this.loadOptions,
          isLoading: projects.isLoading,
          defaultOptions: true,
          ...selectProps,
        }}
        subtitle={subtitle}
        value={input.value}
        width={1}
      />
    )
  }
}

FieldRenderer.defaultProps = { selectProps: {}, subtitle: null }

FieldRenderer.propTypes = {
  input: PropTypes.object.isRequired,
  projects: PropTypes.object.isRequired,
  selectProps: PropTypes.object,
  subtitle: PropTypes.string,
  onSearchProjects: PropTypes.func.isRequired,
}

const ProjectSelector = (props) => (
  <Field component={FieldRenderer} {...props} />
)

export default ProjectSelector
