import React from 'react'
import PropTypes from 'prop-types'

import { Flex } from 'rebass'

import { Input, Label, Wrapper } from './styles'

import Text from '../Text'

const Checkbox = ({ checked, onChange, children }) => (
  <Flex>
    <Wrapper>
      <Input checked={checked} onChange={onChange} />

      <Label />
    </Wrapper>
    {children && (
      <Text
        color={checked ? 'dodgerBlue' : 'brownishGray'}
        fontSize={12}
        fontWeight="bold"
        ml={2}
      >
        {children}
      </Text>
    )}
  </Flex>
)

Checkbox.defaultProps = {
  checked: false,
  onChange: () => null,
  children: null,
}
Checkbox.propTypes = {
  checked: PropTypes.bool,
  children: PropTypes.node,
  onChange: PropTypes.func,
}

export default Checkbox
