import { DateTime } from 'luxon'

import findKey from 'lodash/findKey'
import get from 'lodash/get'
import isEqual from 'lodash/isEqual'

import { PROJECT_TIME_ZONES } from 'Constants/types'

import apiCall from 'Services/Api'

import { getProjectById } from 'Store/Selectors/admin/projects'
import { createAsyncAction } from 'Store/utils'

export const LOAD_TOKEN = createAsyncAction('admin/reports/LOAD_TOKEN')
export const LOAD_EMPLOYEE_WEEKLY_PAYROLL = createAsyncAction(
  'admin/reports/LOAD_EMPLOYEE_WEEKLY_PAYROLL',
)
export const LOAD_PROJECT_WEEKLY = createAsyncAction(
  'admin/reports/LOAD_PROJECT_WEEKLY',
)
export const LOAD_SUBCONTRACTOR_WEEKLY = createAsyncAction(
  'admin/reports/LOAD_SUBCONTRACTOR_WEEKLY',
)
export const LOAD_SUBCONTRACTOR_COST_CODE = createAsyncAction(
  'admin/reports/LOAD_SUBCONTRACTOR_COST_CODE',
)
export const LOAD_LABOR_TIME_CARD = createAsyncAction(
  'admin/reports/LOAD_LABOR_TIME_CARD',
)
export const LOAD_PROJECT_DAILY_INTERNAL = createAsyncAction(
  'admin/reports/LOAD_PROJECT_DAILY_INTERNAL',
)
export const LOAD_PROJECT_DAILY_EXTERNAL = createAsyncAction(
  'admin/reports/LOAD_PROJECT_DAILY_EXTERNAL',
)
export const LOAD_EMPLOYEE_HOURS = createAsyncAction(
  'admin/reports/LOAD_EMPLOYEE_HOURS',
)
export const LOAD_DAILY_FORMAN_NOTES = createAsyncAction(
  'admin/reports/LOAD_DAILY_FORMAN_NOTES',
)
export const UPDATE_SHIFT_TIME = createAsyncAction(
  'admin/reports/UPDATE_SHIFT_TIME',
)
export const DELETE_SHIFTS_TIME = createAsyncAction(
  'admin/reports/DELETE_SHIFTS_TIME',
)

export const loadToken = () =>
  apiCall({
    method: 'POST',
    endpoint: '/token',
    types: LOAD_TOKEN,
    needsNormalization: false,
  })

export const loadEmployeeWeeklyPayroll = (startDate) =>
  apiCall({
    endpoint: '/admin/reports/employee_weekly_payroll',
    types: LOAD_EMPLOYEE_WEEKLY_PAYROLL,
    needsNormalization: false,
    query: {
      start_date: DateTime.fromJSDate(startDate).toISODate(),
      finish_date: DateTime.fromJSDate(startDate).plus({ days: 6 }).toISODate(),
    },
  })

export const loadSubcontracorWeekly = ({
  startDate,
  subcontractorProviderId,
}) =>
  apiCall({
    endpoint: '/admin/reports/subcontractor_weekly',
    types: LOAD_SUBCONTRACTOR_WEEKLY,
    needsNormalization: false,
    query: {
      start_date: DateTime.fromJSDate(startDate).toISODate(),
      finish_date: DateTime.fromJSDate(startDate).plus({ days: 6 }).toISODate(),
      subcontractorProviderId,
    },
  })

export const loadSubcontracorCostCode = ({
  startDate,
  subcontractorProviderId,
}) =>
  apiCall({
    endpoint: '/admin/reports/subcontractor_cost_code',
    types: LOAD_SUBCONTRACTOR_COST_CODE,
    needsNormalization: false,
    query: {
      start_date: DateTime.fromJSDate(startDate).toISODate(),
      finish_date: DateTime.fromJSDate(startDate).plus({ days: 6 }).toISODate(),
      subcontractorProviderId,
    },
  })

export const loadProjectWeekly = ({ startDate, projectId }) =>
  apiCall({
    endpoint: '/admin/reports/projects/weekly_report',
    types: LOAD_PROJECT_WEEKLY,
    needsNormalization: false,
    query: {
      start_date: DateTime.fromJSDate(startDate).toISODate(),
      finish_date: DateTime.fromJSDate(startDate).plus({ days: 6 }).toISODate(),
      project_id: projectId,
    },
  })

export const loadLaborTimeCard = (startDate, laborId) =>
  apiCall({
    endpoint: `/users/${laborId}/time_card`,
    types: LOAD_LABOR_TIME_CARD,
    needsNormalization: false,
    query: {
      start_date: DateTime.fromJSDate(startDate).toISODate(),
      finish_date: DateTime.fromJSDate(startDate).plus({ days: 6 }).toISODate(),
    },
  })

export const loadProjectDailyInternal = (reportDate, projectId) =>
  apiCall({
    endpoint: `/admin/reports/projects/daily_report_internal`,
    types: LOAD_PROJECT_DAILY_INTERNAL,
    needsNormalization: false,
    query: {
      date: DateTime.fromJSDate(reportDate).toISODate(),
      project_id: projectId,
    },
  })

export const loadProjectDailyExternal = (reportDate, projectId) =>
  apiCall({
    endpoint: `/admin/reports/projects/daily_report_external`,
    types: LOAD_PROJECT_DAILY_EXTERNAL,
    needsNormalization: false,
    query: {
      date: DateTime.fromJSDate(reportDate).toISODate(),
      project_id: projectId,
    },
  })

export const loadEmployeeHours = ({ reportDate }) =>
  apiCall({
    endpoint: `/admin/reports/employee_hours`,
    types: LOAD_EMPLOYEE_HOURS,
    needsNormalization: false,
    query: {
      start_date: DateTime.fromJSDate(reportDate.from).toISODate(),
      finish_date: DateTime.fromJSDate(reportDate.to).toISODate(),
    },
  })

export const loadDailyFormanNotes = (reportDate, projectId) =>
  apiCall({
    endpoint: `/admin/reports/projects/daily_forman_notes`,
    types: LOAD_DAILY_FORMAN_NOTES,
    needsNormalization: false,
    query: {
      date: DateTime.fromJSDate(reportDate).toISODate(),
      project_id: projectId,
    },
  })

export const updateShiftTime = ({
  checkinId,
  projectId,
  projectTimeZone,
  date,
  startAt,
  finishAt,
  startChangeReason,
  finishChangeReason,
}) => (dispatch, getState) => {
  const DATE_FORMAT = 'LL/dd/y HH:mm'
  let zoneName = projectTimeZone

  if (!zoneName) {
    const project = getProjectById(projectId)(getState())

    zoneName = get(project, 'projectTimeZone')
  }

  const zone = findKey(PROJECT_TIME_ZONES, (value) => isEqual(value, zoneName))

  return apiCall({
    method: 'PATCH',
    endpoint: `/admin/checkins/${checkinId}`,
    types: UPDATE_SHIFT_TIME,
    needsNormalization: false,
    query: {
      include: 'checkinsChanges',
      data: {
        type: 'checkins',
        attributes: {
          startAt: startAt
            ? DateTime.fromFormat(`${date} ${startAt.value}`, DATE_FORMAT, {
                zone,
              }).toISO()
            : undefined,
          finishAt: finishAt
            ? DateTime.fromFormat(`${date} ${finishAt.value}`, DATE_FORMAT, {
                zone,
              }).toISO()
            : undefined,
          start_change_reason: startChangeReason,
          finish_change_reason: finishChangeReason,
        },
      },
    },
  })(dispatch, getState)
}

export const removeShiftsTime = ({ shifts }) =>
  apiCall({
    method: 'DELETE',
    endpoint: '/admin/checkins',
    query: [...shifts],
    headers: {
      'Content-Type': 'application/json',
    },
    types: DELETE_SHIFTS_TIME,
    needsNormalization: false,
  })
