import { connect } from 'react-redux'

import { compose } from 'recompose'

import { deactivateAdmin, deleteAdmin } from 'Store/Actions/admin/admins'
import { deactivateLabor, deleteLabor } from 'Store/Actions/admin/labors'

import DeleteUser from './DeleteUser'

export default compose(
  connect(null, {
    onDeleteLabor: deleteLabor,
    onDeleteAdmin: deleteAdmin,
    onDeactivateLabor: deactivateLabor,
    onDeactivateAdmin: deactivateAdmin,
  }),
)(DeleteUser)
