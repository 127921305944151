import { createFields, createReducerHandlers } from 'rapidux'

import { LOAD_CHECKINS } from 'Store/Actions/admin/checkins'
import { LOG_OUT } from 'Store/Actions/viewer'
import { createReducer } from 'Store/utils'

const initialState = {
  ...createFields('checkins'),
  paged: {},
}
const handlers = {
  ...createReducerHandlers('checkins', LOAD_CHECKINS, { withReplace: true }),

  [LOG_OUT]: (state) => state.merge(initialState),
}

export default createReducer(initialState, handlers)
