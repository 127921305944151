import React, { PureComponent } from 'react'
import { toast } from 'react-toastify'
import PropTypes from 'prop-types'

import get from 'lodash/get'

import { Toast } from 'Components/Blocks'
import { Modal, Text, Title } from 'Components/UI'

import {
  ButtonContainer,
  Container,
  ContentContainer,
  Error,
  StyledButton,
} from './styles'

class DeleteUser extends PureComponent {
  state = {
    error: null,
  }

  handleSubmit = async (actionType) => {
    const {
      userType,
      data,
      onDeleteLabor,
      onCallback,
      onDeleteAdmin,
      onDeactivateLabor,
      onDeactivateAdmin,
    } = this.props

    // Deactivation
    if (actionType === 'deactivate') {
      if (userType === 'labors') {
        const result = await onDeactivateLabor(data.id)

        if (result.ok) {
          toast.success(
            <Toast heading="Labor successfully deactivated" type="success" />,
          )
          onCallback()
        } else {
          this.setState({
            error: 'Something goes wrong. Please try again later...',
          })
        }
      }
      if (userType === 'admins') {
        const result = await onDeactivateAdmin(data.id)

        if (result.ok) {
          toast.success(
            <Toast heading="Admin successfully deactivated" type="success" />,
          )
          onCallback()
        } else {
          this.setState({
            error: 'Something goes wrong. Please try again later...',
          })
        }
      }
    } else {
      // Delete
      if (userType === 'labors') {
        const result = await onDeleteLabor(data.id)
        if (result.ok) {
          toast.success(
            <Toast heading="Labor successfully deleted" type="success" />,
          )
          onCallback()
        } else {
          this.setState({
            error: 'Something goes wrong. Please try again later...',
          })
        }
      }
      if (userType === 'admins') {
        const result = await onDeleteAdmin(data.id)
        if (result.ok) {
          toast.success(
            <Toast heading="Admin successfully deleted" type="success" />,
          )
          onCallback()
        } else {
          this.setState({
            error: 'Something goes wrong. Please try again later...',
          })
        }
      }
    }
  }

  handleCallback = () => {
    const { onCallback } = this.props
    this.setState({ error: null })
    onCallback()
  }

  renderContent = () => {
    const { error } = this.state
    const { data } = this.props
    const fullName = `${get(data, 'profile.firstName')} ${get(
      data,
      'profile.lastName',
    )}`

    const isUserDeactivated = get(data, 'state') === 'deactivated'

    return (
      <Container>
        <Title.H2 color="brownishGray" mb={4} mt={1} textAlign="center">
          User Deleting
        </Title.H2>

        <ContentContainer>
          <Text>
            Are you sure you want to delete <b>{fullName}</b> from the system?
            <br />
            All the data will be deleted and may not be restored.
            <br />
            <br />
            In case you need to keep data on this User, better Deactivate the
            profile.
          </Text>
        </ContentContainer>

        <ButtonContainer>
          <StyledButton
            buttonType="grey"
            mt={4}
            secondary
            width={1 / 4}
            onClick={this.handleCallback}
          >
            Cancel
          </StyledButton>

          {!isUserDeactivated && (
            <StyledButton
              mt={4}
              secondary
              width={1 / 4}
              onClick={() => this.handleSubmit('deactivate')}
            >
              Deactivate
            </StyledButton>
          )}

          <StyledButton
            buttonType="red"
            mt={4}
            secondary
            width={1 / 4}
            onClick={this.handleSubmit}
          >
            Delete
          </StyledButton>
        </ButtonContainer>

        {error && <Error>{error}</Error>}
      </Container>
    )
  }

  render() {
    const { isOpen } = this.props

    return (
      <Modal isOpen={isOpen} onCallback={this.handleCallback}>
        <Modal.Close onClick={this.handleCallback} />
        {this.renderContent()}
      </Modal>
    )
  }
}

DeleteUser.propTypes = {
  data: PropTypes.object.isRequired,
  isOpen: PropTypes.bool.isRequired,
  userType: PropTypes.string.isRequired,
  onCallback: PropTypes.func.isRequired,
  onDeactivateAdmin: PropTypes.func.isRequired,
  onDeactivateLabor: PropTypes.func.isRequired,
  onDeleteAdmin: PropTypes.func.isRequired,
  onDeleteLabor: PropTypes.func.isRequired,
}

export default DeleteUser
