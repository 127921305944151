import React from 'react'
import PropTypes from 'prop-types'

import { Box, Flex, Heading, Text } from 'rebass'

import { alertGlyph, checkCircleGlyph, informationGlyph } from 'Assets/Svg'

import { Icon } from 'Components/UI'

const renderIcon = (type) => {
  switch (type) {
    case 'success':
      return <Icon fill="success" glyph={checkCircleGlyph} mr={3} size={30} />
    case 'error':
      return <Icon fill="danger" glyph={alertGlyph} mr={3} size={30} />
    case 'info':
      return <Icon fill="coolBlue" glyph={informationGlyph} mr={3} size={30} />
    default:
      return null
  }
}

const Toast = ({ type, heading, text }) => (
  <Flex alignItems="center">
    {renderIcon(type)}

    <Box>
      {heading && <Heading fontSize={3}>{heading}</Heading>}

      {text && (
        <Text color="blueyGrey" fontSize={1} fontWeight="normal">
          {text}
        </Text>
      )}
    </Box>
  </Flex>
)

Toast.defaultProps = { type: null, text: null, heading: '' }

Toast.propTypes = {
  heading: PropTypes.string,
  text: PropTypes.string,
  type: PropTypes.string,
}

export default Toast
