import { createFields, createLoadHandler, createReducerHandlers } from 'rapidux'

import {
  ACTIVATE_PROJECT,
  CREATE_PROJECT,
  DEACTIVATE_PROJECT,
  LOAD_PROJECT,
  LOAD_PROJECTS,
} from 'Store/Actions/admin/projects'
import { LOG_OUT } from 'Store/Actions/viewer'
import { createReducer } from 'Store/utils'

const initialState = {
  ...createFields('projects'),
  ...createFields('projects', 'project', { singular: true }),
  paged: {},
}
const handlers = {
  ...createReducerHandlers('projects', LOAD_PROJECTS, {
    withReplace: true,
  }),

  ...createReducerHandlers('projects', LOAD_PROJECT, {
    withReplace: true,
    singular: true,
    mapToKey: 'project',
  }),

  [CREATE_PROJECT.SUCCESS]: createLoadHandler('projects'),
  [ACTIVATE_PROJECT.SUCCESS]: createLoadHandler('projects'),
  [DEACTIVATE_PROJECT.SUCCESS]: createLoadHandler('projects'),

  [LOG_OUT]: (state) => state.merge(initialState),
}

export default createReducer(initialState, handlers)
