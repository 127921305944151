import { createFields, createLoadHandler, createReducerHandlers } from 'rapidux'

import { CREATE_CLIENT, LOAD_CLIENTS } from 'Store/Actions/admin/clients'
import { LOG_OUT } from 'Store/Actions/viewer'
import { createReducer } from 'Store/utils'

const initialState = {
  ...createFields('clients'),
}
const handlers = {
  ...createReducerHandlers('clients', LOAD_CLIENTS, {
    withReplace: true,
  }),

  [CREATE_CLIENT.SUCCESS]: createLoadHandler('clients'),

  [LOG_OUT]: (state) => state.merge(initialState),
}

export default createReducer(initialState, handlers)
