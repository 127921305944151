import React, { PureComponent } from 'react'
import { components } from 'react-select'
import PropTypes from 'prop-types'

import { themeGet } from '@styled-system/theme-get'

import debounce from 'debounce-promise'
import { denormalize } from 'rapidux'

import filter from 'lodash/filter'
import get from 'lodash/get'
import includes from 'lodash/includes'
import map from 'lodash/map'
import omit from 'lodash/omit'
import set from 'lodash/set'

import { Avatar } from 'Components/UI'

import Labor from './Labor'
import {
  AvatarName,
  Collapse,
  Container,
  Header,
  Labors,
  Search,
  SearchIcon,
  SelectList,
  StyledSelect,
  TriangleIcon,
  ValueWrapper,
} from './styles'

const entityToOption = (entity) => ({
  id: entity.id,
  value: entity.id,
  label: `${get(entity, 'profile.firstName')} ${get(
    entity,
    'profile.lastName',
  )}`,
  image: get(entity, 'profile.personalPhoto.content.smallThumb.url'),
})

const ValueContainer = (props) => (
  <ValueWrapper>
    <components.ValueContainer {...props} />
  </ValueWrapper>
)

class Role extends PureComponent {
  loadOptions = debounce(async (inputValue) => {
    const { onSearchLabors, laborsIds } = this.props

    const {
      payload: { data },
    } = await onSearchLabors(inputValue, {
      profile: { onboardingCompleted: { eq: true } },
      state: { neq: 'deactivated' },
    })

    set(data, 'labors', omit(get(data, 'labors'), laborsIds))

    const options = denormalize(data, 'labors') || []
    return options.map(entityToOption)
  }, 500)

  constructor(props) {
    super(props)

    this.state = {
      opened: true,
      values: get(props, 'role.labors') || [],
    }
  }

  handleToggleCollapse = () => {
    this.setState((state) => ({
      opened: !state.opened,
    }))
  }

  handleRemoveLabor = (labor) => {
    const { values } = this.state
    const { role, onChange } = this.props

    const nextValues = filter(values, (entity) => entity.id !== labor.id)

    onChange(role, nextValues)

    this.setState({
      values: nextValues,
    })
  }

  handleChangeLabors = (values, { action }) => {
    switch (action) {
      case 'remove-value':
      case 'pop-value':
      case 'clear':
        return
      default: {
        const { role, onChange } = this.props

        onChange(role, values)

        this.setState({
          values,
        })
        break
      }
    }
  }

  render() {
    const { role, laborsIds } = this.props
    const { values, opened } = this.state
    return (
      <Container>
        <Header onClick={this.handleToggleCollapse}>
          {role.label} ({values.length})
          <TriangleIcon opened={opened} />
        </Header>
        <Collapse isOpened={opened}>
          <Search>
            <SearchIcon />
            <StyledSelect
              async
              closeMenuOnSelect={false}
              components={{
                ValueContainer,
                Option: (props) =>
                  !includes(laborsIds, props.data.id) && (
                    <components.Option {...props}>
                      <SelectList>
                        <AvatarName>
                          <Avatar
                            borderColor={themeGet('colors.dodgerBlue')}
                            size={24}
                            src={props.data.image}
                          />
                          <p>{props.data.label}</p>
                        </AvatarName>
                        <div>ID: {props.data.id}</div>
                      </SelectList>
                    </components.Option>
                  ),
                MultiValue: StyledSelect.emptyComponent,
                DropdownIndicator: StyledSelect.emptyComponent,
                IndicatorSeparator: StyledSelect.emptyComponent,
                ClearIndicator: StyledSelect.emptyComponent,
                Placeholder: StyledSelect.emptyComponent,
              }}
              defaultOptions
              isMulti
              loadOptions={this.loadOptions}
              options={map(role.labors, entityToOption)}
              value={values}
              width={280}
              onChange={this.handleChangeLabors}
            />
          </Search>
          <Labors>
            {map(values, (value) => (
              <Labor
                key={value.value}
                labor={value}
                onRemove={this.handleRemoveLabor}
              />
            ))}
          </Labors>
        </Collapse>
      </Container>
    )
  }
}

Role.propTypes = {
  laborsIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  role: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  onSearchLabors: PropTypes.func.isRequired,
}

export default Role
