import { STEP_NAMES } from './onboarding'

export const ROOT_PATH = '/'
export const APP_ROOT = `${ROOT_PATH}app/`
export const ADMIN_ROOT = `${ROOT_PATH}admin/`
export const AUTH_ROOT = `${ROOT_PATH}auth/`
export const ONBOARDING_ROOT = `${ROOT_PATH}onboarding/`

// Onboarding paths
export const ONBOARDING_PATHS = {
  [STEP_NAMES.BASIC]: `${ONBOARDING_ROOT}basic`,
  [STEP_NAMES.PHOTO]: `${ONBOARDING_ROOT}photo`,
  [STEP_NAMES.PHONE]: `${ONBOARDING_ROOT}phone`,
  [STEP_NAMES.PHONE_VERIFICATION]: `${ONBOARDING_ROOT}phone/verification`,
  [STEP_NAMES.BADGE_AKNOWLEDGEMENT]: `${ONBOARDING_ROOT}badge-aknowledgement`,
}

// Admin paths
export const ADMIN_PATHS = {
  PROJECTS: `${ADMIN_ROOT}projects`,
  PROJECT_CREATE: `${ADMIN_ROOT}projects/create`,
  PROJECT: (projectId) => `${ADMIN_ROOT}projects/${projectId || ':projectId'}`,
  DASHBOARD: `${ADMIN_ROOT}dashboard`,
  ADMINS: `${ADMIN_ROOT}admins`,
  ADMIN: (adminId) => `${ADMIN_ROOT}admins/${adminId || ':adminId'}`,
  INVITATIONS: `${ADMIN_ROOT}invitations`,
  LABOR: `${ADMIN_ROOT}labor`,
  LABOR_DETAILS: (laborId) => `${ADMIN_ROOT}labor/${laborId || ':laborId'}`,
  REPORTS: `${ADMIN_ROOT}reports`,
  CLIENTS: `${ADMIN_ROOT}clients`,
  SUBCONTRACTOR_PROVIDERS: `${ADMIN_ROOT}subcontractor-providers`,
  SUBCONTRACTOR_PROVIDER: (subcontractorProviderId) =>
    `${ADMIN_ROOT}subcontractor-providers/${
      subcontractorProviderId || ':subcontractorProviderId'
    }`,
  ROLES: `${ADMIN_ROOT}roles`,
}

// App paths
export const APP_PATHS = {
  DASHBOARD: `${APP_ROOT}dashboard`,
  SETTINGS: `${APP_ROOT}settings`,
}

// Public paths
export const PUBLIC_PATHS = {
  PRIVACY_POLICY: `${ROOT_PATH}privacy`,
  SUPPORT: `${ROOT_PATH}support`,
}

// Auth paths
export const AUTH_PATHS = {
  SIGN_IN: `${AUTH_ROOT}sign-in`,
  SIGN_UP: `${AUTH_ROOT}sign-up`,
  CONFIRM_EMAIL: `${AUTH_ROOT}confirm-email`,
  FORGOT_PASSWORD: `${AUTH_ROOT}forgot-password`,
  CHANGE_PASSWORD: `${AUTH_ROOT}change-password`,
}
