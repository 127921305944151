import I18n from 'i18n-js'

import en from './translations/en.json'
import es from './translations/es.json'

export const mainTranslationsSet = { en, es }

export function initTranslations() {
  I18n.translations = mainTranslationsSet
  I18n.missingTranslation = (key) => `![${key}]`
}

export default (translate, options = null) => I18n.t(translate, options)
