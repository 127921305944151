import React from 'react'
import { Field } from 'react-final-form'
import { connect } from 'react-redux'

import { createStructuredSelector } from 'reselect'

import get from 'lodash/get'

import { uploadFile } from 'Store/Actions/uploading'
import { getImage } from 'Store/Selectors/images'

import DocUploader from './DocUploader'

const ConnectedComponent = connect(
  createStructuredSelector({
    image: (state, props) => getImage(get(props, 'input.value'))(state, props),
  }),
  { onUploadFile: uploadFile },
)(DocUploader)

export default (props) => (
  <Field allowNull {...props} component={ConnectedComponent} />
)
