import { combineReducers } from 'redux'

import basic from './basic'
import military from './military'
import race from './race'

export default combineReducers({
  basic,
  race,
  military,
})
