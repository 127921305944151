import React, { Fragment, PureComponent } from 'react'
import { Prompt, withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'

import { Flex, Text } from 'rebass'

import get from 'lodash/get'
import isEqual from 'lodash/isEqual'

import { Button, Modal } from 'Components/UI'

class UnsavedChangesChecker extends PureComponent {
  state = { modalIsOpen: false }

  onHandleBlockedNavigation = (nextLocation) => {
    const { modalIsOpen } = this.state

    if (
      isEqual(get(nextLocation.state, 'withoutChecking'), true) ||
      modalIsOpen
    ) {
      return true
    }

    this.setState({ modalIsOpen: true, nextLocation })

    return false
  }

  handleCallback = () => this.setState({ modalIsOpen: false })

  handleOnSave = async () => {
    const { handleSubmit } = this.props

    const submitSucceeded = await handleSubmit()

    if (submitSucceeded) {
      this.handleOnGoNext()
    } else {
      this.handleCallback()
    }
  }

  handleOnGoNext = () => {
    const { history } = this.props
    const { nextLocation } = this.state

    history.push(nextLocation)
  }

  render = () => {
    const { initialValues, values, submitting } = this.props
    const { modalIsOpen } = this.state

    return (
      <>
        <Prompt
          message={this.onHandleBlockedNavigation}
          when={!isEqual(initialValues, values)}
        />

        <Modal isOpen={modalIsOpen} onCallback={this.handleCallback}>
          <Modal.Close onClick={this.handleCallback} />

          <Flex flexDirection="column" justifyContent="space-between">
            <Text fontSize={3} mt={5}>
              You are going to quit this page. Do you want to save the changes?
            </Text>

            <Flex justifyContent="flex-end" mt={5}>
              <Button disabled={submitting} mr={2} onClick={this.handleOnSave}>
                Yes, save
              </Button>

              <Button secondary onClick={this.handleOnGoNext}>
                No, don't save
              </Button>
            </Flex>
          </Flex>
        </Modal>
      </>
    )
  }
}

UnsavedChangesChecker.defaultProps = { initialValues: {} }
UnsavedChangesChecker.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  initialValues: PropTypes.object,
  submitting: PropTypes.bool.isRequired,
  values: PropTypes.object.isRequired,
}

export default withRouter(UnsavedChangesChecker)
