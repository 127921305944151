import get from 'lodash/get'
import values from 'lodash/values'

import { setIn } from 'Services/Utils'

import { LOAD_LABOR } from 'Store/Actions/admin/labors'
import { createReducer } from 'Store/utils'

const initialState = {
  ready: false,
}

const handlers = {
  [LOAD_LABOR.SUCCESS]: (state, action) => {
    const meta = values(get(action.payload, 'data.meta'))
    const workHistory = get(meta, '[0].meta.workHistory')
    const userId = get(meta, '[0].data[0].id')

    if (workHistory) {
      return setIn(state, [userId, 'attributes', 'workHistory'], workHistory)
    }

    return state
  },
}

export default createReducer(initialState, handlers)
