import styled from 'styled-components'
import { themeGet } from '@styled-system/theme-get'

import { Box } from 'rebass'

export const FormContainer = styled(Box).attrs({
  m: 4,
  as: 'form',
})``

export const Error = styled(Box)`
  color: ${themeGet('colors.danger')};
  text-align: center;
`
