import React, { Fragment, PureComponent } from 'react'
import Dropzone from 'react-dropzone'
import { toast } from 'react-toastify'
import PropTypes from 'prop-types'

import { Link } from 'rebass'

import get from 'lodash/get'
import head from 'lodash/head'

import { closeGlyph, cloudUploadGlyph, pdfFileGlyph } from 'Assets/Svg'

import { Toast } from 'Components/Blocks'
import { Button, Icon, Loader, Text, Tip } from 'Components/UI'

import {
  Container,
  DeleteButton,
  FileWrapper,
  Panel,
  TipWrapper,
} from './styles'

class EmployeePackageUploader extends PureComponent {
  state = { isLoading: false }

  get type() {
    const { adminId } = this.props

    return adminId ? 'admins' : 'labors'
  }

  handleDrop = async (files = []) => {
    const { onUploadFile, adminId, laborId } = this.props
    const file = head(files)

    if (file) {
      this.setState({ isLoading: true })

      const id = this.type === 'admins' ? adminId : laborId
      const {
        ok,
      } = await onUploadFile(
        `/admin/${this.type}/${id}/employee_packages`,
        file,
        'content',
        { include: 'employeePackage' },
      )

      if (ok) {
        this.setState({ isLoading: false })

        toast.success(
          <Toast
            heading="Badge Acknowledgement Form uploaded"
            type="success"
          />,
        )
      } else {
        this.setState({ isLoading: false })

        toast.error(
          <Toast
            heading="Something goes wrong"
            text="Cannot upload package"
            type="error"
          />,
        )
      }
    }
  }

  handleOnClickDelete = async () => {
    const { employeePackage, onDelete, onSuccess } = this.props
    const employeePackageId = get(employeePackage, 'id')

    if (employeePackageId) {
      this.setState({ isLoading: true })

      const { ok } = await onDelete(employeePackageId, this.type)

      if (ok) {
        this.setState({ isLoading: false })

        toast.success(
          <Toast heading="Badge Acknowledgement Form deleted" type="success" />,
        )

        onSuccess()
      } else {
        this.setState({ isLoading: false })

        toast.error(
          <Toast
            heading="Something goes wrong"
            text="Cannot delete Badge Acknowledgement Form"
            type="error"
          />,
        )
      }
    }
  }

  renderUploader = (open, getRootProps) => {
    const { isLoading } = this.state
    const { employeePackage } = this.props

    if (isLoading) return <Loader />

    if (employeePackage) {
      return (
        <Panel>
          <Text color="secondary" fontWeight="bold" mb={3}>
            Badge Acknowledgement Form
          </Text>

          <FileWrapper>
            <DeleteButton onClick={this.handleOnClickDelete}>
              <Icon fill="white" glyph={closeGlyph} size={8} />
            </DeleteButton>

            <Icon fill="secondary" glyph={pdfFileGlyph} size={50} />
          </FileWrapper>

          <Link
            color="secondary"
            download={get(employeePackage, 'name')}
            href={get(employeePackage, 'content.url')}
            mb={3}
            mt={2}
            target="_blank"
          >
            {get(employeePackage, 'name')}
          </Link>

          <Button secondary onClick={open}>
            Change document
          </Button>
        </Panel>
      )
    }

    return (
      <Panel {...getRootProps()} border="1">
        <Icon fill="secondary" glyph={cloudUploadGlyph} size={50} />

        <Text fontWeight="bold" my={2}>
          Drag-and-drop Badge Acknowledgement Form or click here to upload it.
        </Text>

        <Text mb={3}>
          File format should be pdf. Max file size should be 500MB.
        </Text>

        <Button secondary>or select files</Button>
      </Panel>
    )
  }

  render = () => {
    const { employeePackage } = this.props

    return (
      <Container>
        <TipWrapper>
          <Tip>
            Badge Acknowledgement Form package includes Work site agreement,
            Receipt and understanding, W4, Direct deposit form, Medical
            questionnaire form, I9 and Badge form. Badge Acknowledgement Form
            should be a single pdf-file.
          </Tip>
        </TipWrapper>

        <Dropzone
          accept="application/pdf"
          multiple={false}
          noClick={!!employeePackage}
          noDrag={!!employeePackage}
          onDrop={this.handleDrop}
        >
          {({ getRootProps, getInputProps, open }) => (
            <>
              <input {...getInputProps()} />

              {this.renderUploader(open, getRootProps)}
            </>
          )}
        </Dropzone>
      </Container>
    )
  }
}

EmployeePackageUploader.defaultProps = {
  adminId: null,
  employeePackage: null,
  laborId: null,
}
EmployeePackageUploader.propTypes = {
  adminId: PropTypes.string,
  employeePackage: PropTypes.object,
  laborId: PropTypes.string,
  onDelete: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  onUploadFile: PropTypes.func.isRequired,
}

export default EmployeePackageUploader
