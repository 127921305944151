import React from 'react'

// import PropTypes from 'prop-types'
import { APP_PATHS, APP_ROOT, ROOT_PATH } from 'Constants/paths'

import { Bar, Bottom, Container, Label, Menu, StyledNav, Top } from './styles'

const Sidebar = () => (
  <Container>
    <Bar>
      <Menu>
        <Top>
          <StyledNav exact href={ROOT_PATH} logo="true" to={APP_ROOT}>
            Logo
          </StyledNav>
          <StyledNav exact href={APP_PATHS.DASHBOARD} to={APP_PATHS.DASHBOARD}>
            <Label>Dashboard</Label>
          </StyledNav>
          <StyledNav exact href={APP_PATHS.SETTINGS} to={APP_PATHS.SETTINGS}>
            <Label>Settings</Label>
          </StyledNav>
        </Top>
        <Bottom />
      </Menu>
    </Bar>
  </Container>
)

Sidebar.propTypes = {
  //  viewer: PropTypes.object.isRequired,
}

export default Sidebar
