import React, { PureComponent } from 'react'
import { Field } from 'react-final-form'
import PropTypes from 'prop-types'

import debounce from 'debounce-promise'
import { denormalize } from 'rapidux'

import get from 'lodash/get'
import map from 'lodash/map'
import reduce from 'lodash/reduce'

import { Select } from 'Components/Blocks/Forms'

const entityToOption = (entity) => ({
  id: get(entity, 'id'),
  value: get(entity, 'id'),
  label: get(entity, 'name'),
})

class FieldRenderer extends PureComponent {
  loadOptions = debounce(async (inputValue) => {
    const { onSearchSubcontractorProviders } = this.props

    const {
      payload: { data },
    } = await onSearchSubcontractorProviders(inputValue, {
      state: { eq: 'active' },
    })

    const options = denormalize(data, 'subcontractorProviders') || []

    return map(options, entityToOption)
  }, 500)

  get subcontractorProviders() {
    const { subcontractorProviders } = this.props

    return reduce(
      subcontractorProviders.entities,
      (acc, subcontractorProvider) => {
        acc[subcontractorProvider.id] = subcontractorProvider
        return acc
      },
      {},
    )
  }

  render = () => {
    const { input, subcontractorProviders, selectProps, label } = this.props

    return (
      <Select
        components={{
          ClearIndicator: Select.emptyComponent,
        }}
        label={label.length > 0 ? label : ''}
        name={input.name}
        options={map(this.projects, entityToOption)}
        placeholder="Search by Sub-Contractor Company Providers"
        selectProps={{
          async: true,
          loadOptions: this.loadOptions,
          isLoading: subcontractorProviders.isLoading,
          defaultOptions: true,
          ...selectProps,
        }}
        value={input.value}
        width={1}
      />
    )
  }
}

FieldRenderer.defaultProps = { selectProps: {}, label: 'Sub-Contractor' }

FieldRenderer.propTypes = {
  input: PropTypes.object.isRequired,
  label: PropTypes.string,
  selectProps: PropTypes.object,
  subcontractorProviders: PropTypes.object.isRequired,
  onSearchSubcontractorProviders: PropTypes.func.isRequired,
}

const ReportSubcontractorProvidersSelector = (props) => (
  <Field component={FieldRenderer} {...props} />
)

export default ReportSubcontractorProvidersSelector
