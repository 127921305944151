import styled, { css } from 'styled-components'
import { themeGet } from '@styled-system/theme-get'

import { Box, Button, Flex } from 'rebass'

export const Container = styled(Flex).attrs({
  bg: 'lightGray',
  py: 3,
  px: 5,
  color: 'brownishGray',
})`
  position: relative;
  box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.2);
  height: 250px;
`

export const TipWrapper = styled(Box).attrs({ mt: 3, mr: 4 })`
  position: absolute;
  right: 0;
  top: 0;
`

export const Panel = styled(Flex).attrs({
  height: '100%',
  width: '100%',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  px: 2,
})`
  text-align: center;

  ${(props) =>
    props.border &&
    css`
      border: 1px dashed ${themeGet('colors.brownGray')};
    `}
`

export const FileWrapper = styled(Box)`
  position: relative;
`

export const DeleteButton = styled(Button).attrs({
  bg: 'brownGray',
})`
  border: none;
  position: absolute;
  top: 0;
  right: -40px;
  border-radius: 50%;
  width: 16px;
  height: 16px;
  padding: 0;
  outline: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
`
