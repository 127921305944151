import { connect } from 'react-redux'
import { withRouter } from 'react-router'

import { compose } from 'recompose'

import { withAppContext } from 'Services/Context'

import { inviteAdmin } from 'Store/Actions/admin/admins'

import NewAdmin from './NewAdmin'

export default compose(
  withRouter,
  withAppContext,
  connect(null, {
    onInviteAdmin: inviteAdmin,
  }),
)(NewAdmin)
