import styled from 'styled-components'

import { Flex } from 'rebass'

export const Container = styled(Flex)`
  background: red;
  position: fixed;
  cursor: pointer;
  padding: 8px;
  bottom: 0;
  left: 0;
  right: 0;
  color: white;
  z-index: 100000;
`

export default {}
