import { NavLink } from 'react-router-dom'

import styled, { css } from 'styled-components'
import { mapToTheme } from 'styled-map'
import { themeGet } from '@styled-system/theme-get'

import { Flex, Text } from 'rebass'

export const Container = styled(Flex)`
  height: 100vh;
  width: ${mapToTheme('sizes.sidebarWidth')};
  flex-direction: row;
  position: fixed;
  left: 0;
  z-index: 300;
  transition: height 0.25s ease-in-out;
  order: 1;
  flex: initial;
  background: ${themeGet('colors.primary')};

  @media screen and (max-width: ${themeGet('breakpoints.0')}) {
    right: 0;
    bottom: 0;
    width: 100vw;
    height: ${themeGet('sizes.header')};

    background: ${themeGet('colors.primary')};
  }
`

export const Bar = styled(Flex)`
  -webkit-box-align: center;
  align-items: center;
  color: rgb(222, 235, 255);
  box-sizing: border-box;
  flex-direction: column;
  justify-content: space-between;
  width: ${mapToTheme('sizes.sidebarWidth')};

  @media screen and (max-width: ${themeGet('breakpoints.0')}) {
    flex-direction: row;
    width: 100%;
    height: ${themeGet('sizes.header')};
  }
`

export const Menu = styled(Flex)`
  width: 100%;
  height: 100%;
  flex-direction: column;
  justify-content: space-between;

  @media screen and (max-width: ${themeGet('breakpoints.0')}) {
    justify-content: space-around;
    flex-direction: row;
  }
`

export const Label = styled(Text).attrs({
  as: 'span',
})`
  font-size: 10px;
`

export const StyledNav = styled(NavLink)`
  width: ${mapToTheme('sizes.sidebarWidth')};
  height: ${themeGet('sizes.header')};
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  flex-direction: column;
  color: ${themeGet('colors.white')};

  svg {
    fill: ${themeGet('colors.white')};
  }

  ${(props) =>
    !props.logo &&
    css`
      &.active {
        background-color: ${themeGet('colors.white')};
        color: ${themeGet('colors.secondary')};
      }
    `};

  @media screen and (max-width: ${themeGet('breakpoints.0')}) {
    opacity: 0.5;
    text-decoration: none;
    padding-top: 5px;

    ${Label} {
      display: block;
    }

    &.active {
      background: none;
      color: ${themeGet('colors.white')};
      opacity: 1;

      :after {
        display: none;
      }

      svg {
        fill: ${themeGet('colors.white')};
      }
    }

    ${(props) =>
      (props.logo || props.nomobile) &&
      css`
        display: none;
      `};
  }

  .icon {
    width: 40px;
    height: 40px;
    border-radius: 20px;
    background-color: white;
  }
`

export const Top = styled(Flex)`
  flex-direction: column;
`

export const Bottom = styled(Flex)`
  flex-direction: column;
`
