import {
  createDeleteHandler,
  createFields,
  createLoadHandler,
  createReducerHandlers,
} from 'rapidux'

import {
  CREATE_LABOR,
  DELETE_LABOR,
  LOAD_LABOR,
  LOAD_LABORS,
} from 'Store/Actions/admin/labors'
import { LOG_OUT } from 'Store/Actions/viewer'
import { createReducer } from 'Store/utils'

const initialState = {
  ...createFields('labors'),
  ...createFields('labors', 'labor', true),
  paged: {},
}
const handlers = {
  ...createReducerHandlers('labors', LOAD_LABORS, {
    withReplace: true,
  }),

  ...createReducerHandlers('labors', LOAD_LABOR, {
    withReplace: true,
    mapToKey: 'labor',
    singular: true,
  }),

  [CREATE_LABOR.SUCCESS]: createLoadHandler('labors'),
  [DELETE_LABOR.SUCCESS]: createDeleteHandler('labors'),

  [LOG_OUT]: (state) => state.merge(initialState),
}

export default createReducer(initialState, handlers)
