import React from 'react'
import { Field } from 'react-final-form'
import PropTypes from 'prop-types'

import get from 'lodash/get'
import has from 'lodash/has'
import pick from 'lodash/pick'

import { Input as BaseInput } from './styles'

import { Container, Label, Tooltip } from '../Input/styles'

const renderField = ({
  input,
  meta,
  disabled,
  label,
  placeholder,
  type,
  className,
  isScriptLoaded,
  onBlur,
  ...rest
}) => {
  const error = meta.touched && meta.error ? 1 : 0

  if (!isScriptLoaded) return null

  return (
    <Container className={className} error={error}>
      {label && <Label htmlFor={input.id}>{label}</Label>}

      <BaseInput
        autoComplete="off"
        error={error}
        type={type}
        {...input}
        disabled={disabled}
        initialValue={get(input.value, 'label', input.value)}
        placeholder={placeholder}
        value={get(input.value, 'label', input.value)}
        onBlur={() => {
          if (has(input, 'onBlur')) {
            input.onBlur()
          }

          if (onBlur) {
            onBlur()
          }
        }}
        onSuggestSelect={(suggest) =>
          get(suggest, 'placeId')
            ? input.onChange(pick(suggest, ['label', 'location']))
            : input.value
        }
        {...rest}
      />

      {meta.touched && meta.error && (
        <Tooltip centered={rest.centered}>
          {meta.error.map((item) => item)}
        </Tooltip>
      )}
    </Container>
  )
}

renderField.defaultProps = {
  className: '',
  disabled: false,
  onBlur: () => true,
}

renderField.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  input: PropTypes.object.isRequired,
  isScriptLoaded: PropTypes.bool.isRequired,
  label: PropTypes.string.isRequired,
  meta: PropTypes.object.isRequired,
  placeholder: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  onBlur: PropTypes.func,
}

const GeosuggestInput = ({ label, name, placeholder, ...rest }) => (
  <Field
    label={label}
    name={name}
    placeholder={placeholder}
    render={renderField}
    {...rest}
  />
)

GeosuggestInput.defaultProps = {
  label: '',
  placeholder: '',
}

GeosuggestInput.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
}

export default GeosuggestInput
