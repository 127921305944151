import { PROFILE_INCLUDES } from 'Constants/includes'

import apiCall from 'Services/Api'
import { transformToRequest } from 'Services/Entities/UserProfile'

import { getPendingPrimaryPhoneNumberId } from 'Store/Selectors/viewer'
import { createAsyncAction } from 'Store/utils'

export const UPDATE_PROFILE = createAsyncAction('onboarding/UPDATE_PROFILE')
export const CREATE_PHONE_NUMBER = createAsyncAction(
  'onboarding/CREATE_PHONE_NUMBER',
)
export const SEND_VERIFICATION_CODE = createAsyncAction(
  'onboarding/SEND_VERIFICATION_CODE',
)
export const VERIFY_PHONE = createAsyncAction('onboarding/VERIFY_PHONE')

export const updateProfile = (values) =>
  apiCall({
    method: 'PATCH',
    endpoint: '/user/profile',
    types: UPDATE_PROFILE,
    query: {
      include: PROFILE_INCLUDES.join(),
      data: {
        type: 'profiles',
        ...transformToRequest(values),
      },
    },
  })

export const createPhoneNumber = (phoneNumber) =>
  apiCall({
    method: 'POST',
    endpoint: '/user/profile/phone_numbers',
    types: CREATE_PHONE_NUMBER,
    query: {
      include: 'verifiedPrimaryPhoneNumber,pendingPrimaryPhoneNumber',
      data: {
        type: 'phoneNumber',
        attributes: {
          phoneNumber,
          countryCode: '+1',
          kind: 'primary',
        },
      },
    },
  })

export const sendVerificationCode = () => (dispatch, getState) => {
  const phoneNumberId = getPendingPrimaryPhoneNumberId(getState())

  return dispatch(
    apiCall({
      method: 'POST',
      endpoint: `/user/profile/phone_numbers/${phoneNumberId}/send_code`,
      types: SEND_VERIFICATION_CODE,
      query: {},
    }),
  )
}

export const verifyPhone = (verificationCode) => (dispatch, getState) => {
  const phoneNumberId = getPendingPrimaryPhoneNumberId(getState())

  return dispatch(
    apiCall({
      method: 'POST',
      endpoint: `/user/profile/phone_numbers/${phoneNumberId}/verify`,
      types: VERIFY_PHONE,
      query: {
        data: {
          type: 'phoneNumbers',
          attributes: { verificationCode },
        },
      },
    }),
  )
}
