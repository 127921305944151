import get from 'lodash/get'
import pick from 'lodash/pick'

export const transformToRequest = (values) => ({
  ...pick(values, ['name', 'ein']),
  address: get(values, 'address.label') || get(values, 'address'),
  latitude: get(values, 'address.location.lat', null),
  longitude: get(values, 'address.location.lng', null),
})

export default {}
