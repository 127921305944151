import React, { PureComponent } from 'react'
import { Field } from 'react-final-form'
import PropTypes from 'prop-types'

import debounce from 'debounce-promise'
import { denormalize } from 'rapidux'

import get from 'lodash/get'
import map from 'lodash/map'
import reduce from 'lodash/reduce'

import { Select } from 'Components/Blocks/Forms'

const entityToOption = (entity) => ({
  id: entity.id,
  value: entity.id,
  label: `${get(entity, 'profile.firstName')} ${get(
    entity,
    'profile.lastName',
  )}`,
  image: get(entity, 'profile.personalPhoto.content.smallThumb.url'),
})

class FieldRenderer extends PureComponent {
  loadOptions = debounce(async (inputValue) => {
    const { onSearchLabors } = this.props

    const {
      payload: { data },
    } = await onSearchLabors(inputValue)

    const options = denormalize(data, 'labors') || []
    return options.map(entityToOption)
  }, 500)

  get labors() {
    const { labors } = this.props
    return reduce(
      labors.entities,
      (acc, labor) => {
        acc[labor.id] = labor
        return acc
      },
      {},
    )
  }

  render = () => {
    const { input, label, labors, isMulti } = this.props

    return (
      <Select
        components={{
          ClearIndicator: Select.emptyComponent,
        }}
        label={label}
        name={input.name}
        options={map(this.labors, entityToOption)}
        placeholder="Search by Labor Name"
        selectProps={{
          async: true,
          loadOptions: this.loadOptions,
          isLoading: labors.isLoading,
          defaultOptions: true,
          isMulti,
        }}
        value={input.value}
        width={1}
        onChange={this.handleChangeRoles}
      />
    )
  }
}

FieldRenderer.defaultProps = { label: 'Labor', isMulti: false }

FieldRenderer.propTypes = {
  input: PropTypes.object.isRequired,
  isMulti: PropTypes.bool,
  label: PropTypes.string,
  labors: PropTypes.object.isRequired,
  onSearchLabors: PropTypes.func.isRequired,
}

const LaborSelector = (props) => <Field component={FieldRenderer} {...props} />

export default LaborSelector
