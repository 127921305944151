import React from 'react'
import { Form } from 'react-final-form'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import { Box, Flex } from 'rebass'
import { createStructuredSelector } from 'reselect'

import noop from 'lodash/noop'

import { magnifyGlyph } from 'Assets/Svg'

import { DayPicker, Input, Select } from 'Components/Blocks/Forms'

import { getFilterForm } from 'Store/Selectors/tables'

import FormStateToRedux from './FormStateToRedux'

const type = 'subcontractorProviderLabors'

const SubcontractorProviderLaborsFilterForm = ({ initialValues }) => (
  <Form initialValues={initialValues} onSubmit={noop}>
    {({ handleSubmit }) => (
      <form onSubmit={handleSubmit}>
        <FormStateToRedux type={type} />

        <Flex alignItems="center" justifyContent="space-between">
          <Flex width={3 / 5}>
            <Box mr={[2, 3, 3, 4]} width={1 / 3}>
              <Select
                height={30}
                label="Status"
                name="status"
                options={[
                  { value: 'all', label: 'Show All' },
                  { value: 'active', label: 'Active' },
                  { value: 'deactivated', label: 'Deactivated' },
                ]}
                placeholder="Show All"
                selectProps={{
                  isSearchable: false,
                }}
              />
            </Box>

            <Box mr={[2, 3, 3, 4]} width={1 / 3}>
              <DayPicker label="Last working day" name="lastWorkedAt" ranged />
            </Box>

            <Box mr={[2, 3, 3, 4]} width={1 / 3}>
              <DayPicker label="Date submitted" name="createdAt" ranged />
            </Box>
          </Flex>

          <Box ml={[2, 3, 3, 4]} width={1 / 4}>
            <Input
              icon={magnifyGlyph}
              iconFill="dodgerBlue"
              iconSize={20}
              label="&nbsp;"
              name="search"
              placeholder=""
            />
          </Box>
        </Flex>
      </form>
    )}
  </Form>
)

SubcontractorProviderLaborsFilterForm.propTypes = {
  initialValues: PropTypes.object.isRequired,
}

export default connect(
  createStructuredSelector({
    initialValues: (state, props) => getFilterForm(type)(state, props),
  }),
)(SubcontractorProviderLaborsFilterForm)
