import React, { PureComponent } from 'react'
import { DateUtils } from 'react-day-picker'
import { Field } from 'react-final-form'
import PropTypes from 'prop-types'

import { DateTime } from 'luxon'

import get from 'lodash/get'
import noop from 'lodash/noop'

import {
  CalendarIcon,
  Container,
  InputWrapper,
  StyledDayPickerInput,
} from './styles'

import { Label, Tooltip } from '../Input/styles'

const FORMAT = 'LL/dd/yyyy'
const FORMAT_RANGE = 'LL/dd/yy'

class DayPicker extends PureComponent {
  constructor(props) {
    super(props)

    this.state = {
      from: get(props, 'input.value.from'),
      to: get(props, 'input.value.to'),
    }
  }

  formatDate = (date) => {
    const { ranged } = this.props
    if (ranged) {
      const { from, to } = DateUtils.addDayToRange(date, this.state)

      if (from && to) {
        if (from.getTime() === to.getTime()) {
          return DateTime.fromJSDate(from).toFormat(FORMAT)
        }

        return `${DateTime.fromJSDate(from).toFormat(
          FORMAT_RANGE,
        )}-${DateTime.fromJSDate(to).toFormat(FORMAT_RANGE)}`
      }

      return ''
    }

    return DateTime.fromJSDate(date).toFormat(FORMAT)
  }

  formatValue = (date) => {
    const { ranged } = this.props

    if (ranged) {
      const { from, to } = date

      if (from && to) {
        if (from.getTime() === to.getTime()) {
          return DateTime.fromJSDate(from).toFormat(FORMAT)
        }

        return `${DateTime.fromJSDate(from).toFormat(
          FORMAT_RANGE,
        )}-${DateTime.fromJSDate(to).toFormat(FORMAT_RANGE)}`
      }

      return ''
    }

    if (date) {
      return DateTime.fromJSDate(date).toFormat(FORMAT)
    }

    return ''
  }

  handleDayChange = (input) => (date, { disabled }) => {
    const { ranged } = this.props

    if (disabled) {
      return
    }

    if (ranged) {
      const range = DateUtils.addDayToRange(date, this.state)
      if (
        (range.from && range.to) ||
        (range.from === null && range.to === null)
      ) {
        input.onChange(range)
      }
      this.setState(range)
    } else {
      input.onChange(date)
    }
  }

  render = () => {
    const { from, to } = this.state
    const {
      meta,
      label,
      input,
      after,
      before,
      disabled,
      disabledDaysOfWeek,
      ...rest
    } = this.props
    const modifiers = { start: from, end: to }
    const { ranged } = this.props
    const { active, touched, error, submitError, dirtySinceLastSubmit } = meta

    const hasError =
      touched && (error || (submitError && !dirtySinceLastSubmit))

    return (
      <Container active={active} error={hasError} ranged={ranged} {...rest}>
        {label && (
          <Label htmlFor={input.id} mb={2}>
            {label}
          </Label>
        )}

        <InputWrapper>
          <StyledDayPickerInput
            clickUnselectsDay={!ranged}
            dayPickerProps={{
              selectedDays: ranged ? [from, { from, to }] : input.value,
              modifiers,
              onDayClick: this.handleDayChange(input),
              disabledDays: { after, before, daysOfWeek: disabledDaysOfWeek },
              isDisabled: disabled,
            }}
            formatDate={this.formatDate}
            hideOnDayClick={!ranged}
            inputProps={{
              readOnly: true,
              autoComplete: 'off',
              placeholder: ranged ? 'mm/dd/yy-mm/dd/yy' : 'mm/dd/yyyy',
              disabled,
            }}
            value={this.formatValue(input.value)}
            onDayChange={ranged ? noop : this.handleDayChange(input)}
          />
          <CalendarIcon withLabel={label} />
        </InputWrapper>

        {meta.touched && meta.error && (
          <Tooltip centered={rest.centered}>
            {meta.error.map((item) => item)}
          </Tooltip>
        )}
      </Container>
    )
  }
}

DayPicker.defaultProps = {
  ranged: false,
  disabled: false,
  label: null,
  after: undefined,
  before: undefined,
  disabledDaysOfWeek: undefined,
}

DayPicker.propTypes = {
  after: PropTypes.object,
  before: PropTypes.object,
  disabled: PropTypes.bool,
  disabledDaysOfWeek: PropTypes.arrayOf(PropTypes.number),
  input: PropTypes.object.isRequired,
  label: PropTypes.string,
  meta: PropTypes.object.isRequired,
  ranged: PropTypes.bool,
}

export default (props) => <Field component={DayPicker} {...props} />
