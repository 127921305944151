import { createFields, createLoadHandler } from 'rapidux'

import forEach from 'lodash/forEach'
import get from 'lodash/get'

import {
  CREATE_ADMIN,
  DELETE_ADMIN,
  LOAD_ADMIN,
  LOAD_ADMINS,
} from 'Store/Actions/admin/admins'
import { LOG_OUT } from 'Store/Actions/viewer'
import { createReducer } from 'Store/utils'

const initialState = {
  ...createFields('admins'),
  ...createFields('admins', 'admin', true),
  paged: {},
}
const handlers = {
  [CREATE_ADMIN.SUCCESS]: createLoadHandler('admins'),
  [LOAD_ADMINS.REQUEST]: (state) =>
    state.merge({
      isLoaded: false,
      isLoading: true,
    }),
  [LOAD_ADMINS.FAILURE]: (state) =>
    state.merge({
      isLoaded: false,
      isLoading: false,
    }),
  [LOAD_ADMINS.SUCCESS]: (state, { payload, paged }) => {
    const admins = {}

    forEach(Object.keys(get(payload, 'data.admins', [])), (id) => {
      admins[id] = 'admins'
    })

    forEach(Object.keys(get(payload, 'data.onboardingAdmins', [])), (id) => {
      admins[id] = 'onboardingAdmins'
    })

    return state.merge({
      admins,
      paged,
      isLoaded: true,
      isLoading: false,
    })
  },
  [LOAD_ADMIN.REQUEST]: (state) =>
    state.merge({
      isLoadedAdmin: false,
      isLoadingAdmin: true,
    }),
  [LOAD_ADMIN.FAILURE]: (state) =>
    state.merge({
      isLoadedAdmin: false,
      isLoadingAdmin: false,
    }),
  [LOAD_ADMIN.SUCCESS]: (state, { payload, paged }) => {
    const admin = {}

    forEach(Object.keys(get(payload, 'data.admins', [])), (id) => {
      admin[id] = 'admins'
    })

    forEach(Object.keys(get(payload, 'data.onboardingAdmins', [])), (id) => {
      admin[id] = 'onboardingAdmins'
    })

    return state.merge({
      admin,
      paged,
      isLoadedAdmin: true,
      isLoadingAdmin: false,
    })
  },

  [DELETE_ADMIN.SUCCESS]: (state, { payload }) => {
    const admins = {
      ...state.admins,
    }
    delete admins[payload.deletedId]
    return state.merge({
      admins,
    })
  },

  [LOG_OUT]: (state) => state.merge(initialState),
}

export default createReducer(initialState, handlers)
