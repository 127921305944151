import styled, { css } from 'styled-components'
import { mapToTheme } from 'styled-map'
import { fontSize, space } from 'styled-system'
import { themeGet } from '@styled-system/theme-get'

import { Box, Flex } from 'rebass'

import { Text } from 'Components/UI'

export const Tooltip = styled(Text).attrs({
  as: 'span',
  fontSize: 1,
})`
  color: ${themeGet('colors.danger')};
  text-align: center;
  padding: 0 10px;
  border-radius: 6px;
  transition: opacity 1s;

  ${fontSize};
`

export const Container = styled(Flex).attrs({
  width: 1,
  mb: 3,
})`
  flex-direction: column;
  position: relative;
  font-size: 16px;
  color: ${themeGet('colors.gray')};

  padding: 1px;
  border-radius: 8px;

  ${(props) =>
    props.joined &&
    css`
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    `};

  ${space};

  input {
    cursor: pointer;
  }

  label {
    margin-bottom: 8px;
  }
`

export const Label = styled(Text).attrs({
  as: 'label',
})`
  color: ${themeGet('colors.brownishGray')};

  ${mapToTheme('inputs.label.font')};
`

export const TipWrapper = styled(Box)`
  border: none;
  position: absolute;
  right: -24px;
  bottom: 10px;
  height: 16px;
  width: 16px;
`
