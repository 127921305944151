import React, { PureComponent } from 'react'
import { Field } from 'react-final-form'
import { toast } from 'react-toastify'
import PropTypes from 'prop-types'

import debounce from 'lodash/debounce'
import get from 'lodash/get'
import isEqual from 'lodash/isEqual'
import map from 'lodash/map'

import { Toast } from 'Components/Blocks'
import { Select } from 'Components/Blocks/Forms'

const entityToOption = (costCode) => ({
  label: get(costCode, 'attributes.code', ''),
  value: get(costCode, 'id', ''),
})

class FieldRenderer extends PureComponent {
  state = { isLoading: false, options: [] }

  onLoadOptions = debounce(async (inputValue) => {
    const { onSearchCostCodes } = this.props

    this.setState({ isLoading: true })
    const { ok, payload } = await onSearchCostCodes(inputValue)

    if (ok) {
      this.setState({ options: map(get(payload, 'data.data'), entityToOption) })
    } else {
      toast.error(
        <Toast
          heading="Something goes wrong"
          text="Cannot load options"
          type="error"
        />,
      )
    }

    this.setState({ isLoading: false })
  }, 500)

  handleInputChange = (inputValue, { action }) => {
    if (isEqual(action, 'input-change')) {
      this.onLoadOptions(inputValue)
    }
  }

  handleCreateOption = async (inputValue) => {
    const { onCreateCostCode, input } = this.props

    const { ok, payload } = await onCreateCostCode(inputValue)

    if (ok) {
      const option = entityToOption(get(payload, 'data.data'))

      this.setState((state) => ({ options: [...state.options, option] }))
      input.onChange([...input.value, option])
    } else {
      toast.error(
        <Toast
          heading="Something goes wrong"
          text="Cannot create option"
          type="error"
        />,
      )
    }
  }

  render = () => {
    const { disabled, isMulti, creatable } = this.props
    const { isLoading, options } = this.state

    return (
      <Select
        components={{
          ClearIndicator: Select.emptyComponent,
        }}
        label="Project Cost Codes*"
        name="costCodes"
        options={options}
        placeholder="Choose Project Cost Codes"
        selectProps={{
          creatable,
          isMulti,
          isDisabled: disabled,
          isLoading,
          onInputChange: this.handleInputChange,
          onCreateOption: this.handleCreateOption,
        }}
        width={1}
      />
    )
  }
}

FieldRenderer.defaultProps = {
  isMulti: true,
  creatable: true,
}
FieldRenderer.propTypes = {
  creatable: PropTypes.bool,
  disabled: PropTypes.bool.isRequired,
  input: PropTypes.object.isRequired,
  isMulti: PropTypes.bool,
  onCreateCostCode: PropTypes.func.isRequired,
  onSearchCostCodes: PropTypes.func.isRequired,
}

const CostCodesSelector = (props) => (
  <Field component={FieldRenderer} {...props} />
)

export default CostCodesSelector
