import React from 'react'
import PropTypes from 'prop-types'

import { Flex, Text } from 'rebass'

import get from 'lodash/get'

import { Column, Row } from './styles'

const ReportProjectInfo = ({ projectInfo }) => (
  <Flex mb={3}>
    <Column>
      <Row>
        <Text fontWeight="bold" pl={5} width={1 / 2}>
          Client:
        </Text>
        <Text fontWeight="bold" textAlign="center" width={1 / 2}>
          {get(projectInfo, 'clientName')}
        </Text>
      </Row>

      <Row>
        <Text color="brownishGray" fontWeight="bold" pl={5} width={1 / 2}>
          Project:
        </Text>
        <Text
          color="brownishGray"
          fontWeight="bold"
          textAlign="center"
          width={1 / 2}
        >
          {get(projectInfo, 'project.name')}
        </Text>
      </Row>

      <Row>
        <Text color="brownishGray" fontWeight="bold" pl={5} width={1 / 2}>
          Client PM:
        </Text>
        <Text
          color="brownishGray"
          fontWeight="bold"
          textAlign="center"
          width={1 / 2}
        >
          {get(projectInfo, 'project.clientPmFullName')}
        </Text>
      </Row>

      <Row>
        <Text color="brownishGray" fontWeight="bold" pl={5} width={1 / 2}>
          Internal Project Number:
        </Text>
        <Text
          color="brownishGray"
          fontWeight="bold"
          textAlign="center"
          width={1 / 2}
        >
          {get(projectInfo, 'project.internalProjectNumber')}
        </Text>
      </Row>

      <Row>
        <Text color="brownishGray" fontWeight="bold" pl={5} width={1 / 2}>
          Project Address:
        </Text>
        <Text
          color="brownishGray"
          fontWeight="bold"
          textAlign="center"
          width={1 / 2}
        >
          {get(projectInfo, 'project.address')}
        </Text>
      </Row>
    </Column>

    <Column>
      <Row>
        <Text fontWeight="bold" pl={5} width={1 / 2}>
          Date:
        </Text>
        <Text fontWeight="bold" textAlign="center" width={1 / 2}>
          {get(projectInfo, 'date')}
        </Text>
      </Row>

      {/* <Row>
        <Text color="brownishGray" fontWeight="bold" pl={5} width={1 / 2}>
          Contract Type:
        </Text>
        <Text
          color="brownishGray"
          fontWeight="bold"
          textAlign="center"
          width={1 / 2}
        >
          {PROJECT_KIND_LABELS[get(projectInfo, 'project.kind')]}
        </Text>
      </Row> */}

      <Row>
        <Text color="brownishGray" fontWeight="bold" pl={5} width={1 / 2}>
          Client Project Number:
        </Text>
        <Text
          color="brownishGray"
          fontWeight="bold"
          textAlign="center"
          width={1 / 2}
        >
          {get(projectInfo, 'project.clientProjectNumber')}
        </Text>
      </Row>

      <Row>
        <Text color="brownishGray" fontWeight="bold" pl={5} width={1 / 2}>
          Lunch Rule:
        </Text>
        <Text
          color="brownishGray"
          fontWeight="bold"
          textAlign="center"
          width={1 / 2}
        >
          {get(projectInfo, 'lunchRules')}
        </Text>
      </Row>

      <Row>
        <Text color="brownishGray" fontWeight="bold" pl={5} width={1 / 2}>
          Client's Week Ending Day:
        </Text>
        <Text
          color="brownishGray"
          fontWeight="bold"
          textAlign="center"
          width={1 / 2}
        >
          {get(projectInfo, 'project.clientWeekEndingDay')}
        </Text>
      </Row>

      <Row>
        <Text color="brownishGray" fontWeight="bold" pl={5} width={1 / 2}>
          OT Rule:
        </Text>
        <Text
          color="brownishGray"
          fontWeight="bold"
          textAlign="center"
          width={1 / 2}
        >
          {get(projectInfo, 'project.otRule')}
        </Text>
      </Row>
    </Column>
  </Flex>
)

ReportProjectInfo.propTypes = {
  projectInfo: PropTypes.object.isRequired,
}

export default ReportProjectInfo
