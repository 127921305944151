import { connect } from 'react-redux'
import { withRouter } from 'react-router'

import { createStructuredSelector } from 'reselect'

import { toggleSidebar } from 'Store/Actions/ui'
import { logOut } from 'Store/Actions/viewer'
import { getIsAuthenticated } from 'Store/Selectors/viewer'

import Header from './Header'

export default withRouter(
  connect(
    createStructuredSelector({
      isAuthenticated: getIsAuthenticated,
    }),
    {
      onToggleSidebar: toggleSidebar,
      onLogOut: logOut,
    },
  )(Header),
)
