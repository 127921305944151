import React, { PureComponent } from 'react'
import { toast } from 'react-toastify'
import PropTypes from 'prop-types'

import get from 'lodash/get'

import { Toast } from 'Components/Blocks'
import { Modal, Text, Title } from 'Components/UI'

import { LABOR_KIND_TYPES } from 'Constants/types'

import {
  ButtonContainer,
  Container,
  ContentContainer,
  Error,
  StyledButton,
} from './styles'

class SwitchToDirect extends PureComponent {
  state = {
    error: null,
  }

  handleSubmit = async () => {
    const { data, onSwitch, onCallback } = this.props
    const laborId = get(data, 'entity.id')

    const params = {
      kind: LABOR_KIND_TYPES.direct,
      laborId,
    }

    const result = await onSwitch(params)

    if (result.ok) {
      toast.success(
        <Toast
          heading="User successfully switched to Direct Employee"
          type="success"
        />,
      )
      onCallback()
    } else {
      this.setState({
        error: 'Something goes wrong. Please try again later...',
      })
    }
  }

  handleCallback = () => {
    const { onCallback } = this.props
    this.setState({ error: null })
    onCallback()
  }

  renderContent = () => {
    const { error } = this.state
    const { data } = this.props
    const subContractorProviderCompany = get(
      data,
      'entity.subcontractorProvider.name',
    )

    return (
      <Container>
        <Title.H2 color="brownishGray" mb={4} mt={1} textAlign="center">
          Switching to Direct Employee
        </Title.H2>

        {subContractorProviderCompany && (
          <ContentContainer>
            <Text>Current Sub-Contractor</Text>
            <br />
            <Text>
              Company Provider Name: <b>{subContractorProviderCompany}</b>
            </Text>
          </ContentContainer>
        )}

        <ButtonContainer>
          <StyledButton
            buttonType="grey"
            mt={4}
            secondary
            width={1 / 3}
            onClick={this.handleCallback}
          >
            Cancel
          </StyledButton>

          <StyledButton
            mt={4}
            secondary
            width={1 / 3}
            onClick={this.handleSubmit}
          >
            Switch
          </StyledButton>
        </ButtonContainer>

        {error && <Error>{error}</Error>}
      </Container>
    )
  }

  render() {
    const { isOpen } = this.props

    return (
      <Modal isOpen={isOpen} onCallback={this.handleCallback}>
        <Modal.Close onClick={this.handleCallback} />
        {this.renderContent()}
      </Modal>
    )
  }
}

SwitchToDirect.propTypes = {
  data: PropTypes.object.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onCallback: PropTypes.func.isRequired,
  onSwitch: PropTypes.func.isRequired,
}

export default SwitchToDirect
