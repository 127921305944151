import { connect } from 'react-redux'

import { createStructuredSelector } from 'reselect'

import { searchSubcontractorProviders } from 'Store/Actions/admin/subcontractor-providers'
import { getSubcontractorProviders } from 'Store/Selectors/admin/subcontractor-providers'

import ReportSubcontractorProvidersSelector from './ReportSubcontractorProvidersSelector'

export default connect(
  createStructuredSelector({
    subcontractorProviders: getSubcontractorProviders,
  }),
  { onSearchSubcontractorProviders: searchSubcontractorProviders },
)(ReportSubcontractorProvidersSelector)
