import { connect } from 'react-redux'
import { withRouter } from 'react-router'

import { compose } from 'recompose'

import { withAppContext } from 'Services/Context'

import { loadProjectCheckins } from 'Store/Actions/admin/checkins'

import LaborsCheckins from './LaborsCheckins'

export default compose(
  withRouter,
  withAppContext,
  connect(null, {
    onLoadCheckins: loadProjectCheckins,
  }),
)(LaborsCheckins)
