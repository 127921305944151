import { createFields, createLoadHandler, createReducerHandlers } from 'rapidux'

import {
  CREATE_SUBCONTRACTOR_PROVIDER,
  LOAD_SUBCONTRACTOR_PROVIDER,
  LOAD_SUBCONTRACTOR_PROVIDER_LABORS,
  LOAD_SUBCONTRACTOR_PROVIDERS,
} from 'Store/Actions/admin/subcontractor-providers'
import { LOG_OUT } from 'Store/Actions/viewer'
import { createReducer } from 'Store/utils'

const initialState = {
  ...createFields('subcontractorProviders'),
  ...createFields('subcontractorProviders', 'subcontractorProvider', true),
}
const handlers = {
  ...createReducerHandlers(
    'subcontractorProviders',
    LOAD_SUBCONTRACTOR_PROVIDERS,
    {
      withReplace: true,
    },
  ),

  ...createReducerHandlers(
    'subcontractorProviders',
    LOAD_SUBCONTRACTOR_PROVIDER,
    {
      withReplace: true,
      mapToKey: 'subcontractorProvider',
      singular: true,
    },
  ),

  ...createReducerHandlers('labors', LOAD_SUBCONTRACTOR_PROVIDER_LABORS, {
    withReplace: true,
    mapToKey: 'subcontractorProviderLabors',
  }),

  [CREATE_SUBCONTRACTOR_PROVIDER.SUCCESS]: createLoadHandler(
    'subcontractorProviders',
  ),

  [LOG_OUT]: (state) => state.merge(initialState),
}

export default createReducer(initialState, handlers)
