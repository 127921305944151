import React from 'react'
import { Helmet } from 'react-helmet'
import { renderRoutes } from 'react-router-config'
import PropTypes from 'prop-types'

import _ from 'I18n'

import { RootContainer } from './styles'

const Root = ({ route }) => (
  <RootContainer>
    <Helmet>
      <title>{_('title')}</title>
    </Helmet>
    {renderRoutes(route.routes)}
  </RootContainer>
)

Root.propTypes = {
  route: PropTypes.object.isRequired,
}

export default Root
