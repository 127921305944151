import React from 'react'
import { Redirect } from 'react-router-dom'

import { ADMIN_PATHS, ADMIN_ROOT } from 'Constants/paths'

import * as AdminPages from 'Containers/Pages/Admin'

const routes = () => {
  const paths = [
    /* Admin onBoarding */
    {
      path: ADMIN_ROOT,
      exact: true,
      component: () => <Redirect to={ADMIN_PATHS.LABOR} />,
    },

    {
      path: ADMIN_PATHS.LABOR,
      exact: true,
      component: AdminPages.Labors,
    },
    {
      path: ADMIN_PATHS.LABOR_DETAILS(),
      exact: true,
      component: AdminPages.Labor,
    },
  ]

  return paths
}

export default routes
