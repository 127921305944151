import { connect } from 'react-redux'

import { compose } from 'recompose'
import { createStructuredSelector } from 'reselect'

import {
  createSubcontractorProvider,
  editSubcontractorProvider,
} from 'Store/Actions/admin/subcontractor-providers'
import { getSubcontractorProviderById } from 'Store/Selectors/admin/subcontractor-providers'

import SubcontractorProvider from './SubcontractorProvider'

export default compose(
  connect(
    createStructuredSelector({
      subcontractorProvider: (state, props) =>
        getSubcontractorProviderById(props.subcontractorProviderId)(
          state,
          props,
        ),
    }),
    {
      onCreateSubcontractorProvider: createSubcontractorProvider,
      onEditSubcontractorProvider: editSubcontractorProvider,
    },
  ),
)(SubcontractorProvider)
