import styled from 'styled-components'
import { themeGet } from '@styled-system/theme-get'

import { Box, Flex } from 'rebass'

export const Column = styled(Box).attrs({
  width: 1 / 2,
})`
  background-color: #fbfbfb;
  border: 1px solid ${themeGet('colors.veryLightPink')};

  &:nth-child(odd) {
    border-right: none;
  }
`

export const Row = styled(Flex).attrs({
  py: 1,
})`
  &:not(:last-child) {
    border-bottom: 1px solid ${themeGet('colors.veryLightPink')};
  }
`
