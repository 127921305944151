import { connect } from 'react-redux'

import { createCostCode, searchCostCodes } from 'Store/Actions/admin/projects'

import CostCodesSelector from './CostCodesSelector'

export default connect(null, {
  onSearchCostCodes: searchCostCodes,
  onCreateCostCode: createCostCode,
})(CostCodesSelector)
