import { connect } from 'react-redux'
import { withRouter } from 'react-router'

import { compose } from 'recompose'

import { withAppContext } from 'Services/Context'

import { createLabor, inviteLabor } from 'Store/Actions/admin/labors'

import NewLabor from './NewLabor'

export default compose(
  withRouter,
  withAppContext,
  connect(null, { onCreateLabor: createLabor, onInviteLabor: inviteLabor }),
)(NewLabor)
