import React from 'react'
import PropTypes from 'prop-types'

import styled, { css } from 'styled-components'
import { mapToTheme } from 'styled-map'
import { height, space } from 'styled-system'
import { themeGet } from '@styled-system/theme-get'

import { Box } from 'rebass'

const Container = styled(Box)`
  position: relative;
`

const Field = styled(Box).attrs({ as: 'textarea', py: 2 })`
  min-height: 40px;
  margin: 0;
  padding: ${mapToTheme('inputs.field.padding')};
  width: 100%;
  border-radius: 4px;
  resize: none;

  ${mapToTheme('inputs.field.font')};
  ${mapToTheme('inputs.field.border')};
  background-color: ${mapToTheme('inputs.field.bg')};

  outline: none;
  -webkit-appearance: none;

  transition: all ease 0.3s;

  ${(props) =>
    props.centered &&
    css`
      text-align: center;
    `};

  :hover,
  :focus {
    border-color: ${mapToTheme('inputs.field.borderHover')};
  }

  ::placeholder {
    color: ${themeGet('colors.input.font.placeholder')};
  }

  ${space};
  ${height};
`

const Input = ({
  label,
  name,
  type,
  error,
  className,
  placeholder,
  ...rest
}) => (
  <Container className={className} error={error}>
    <Field
      error={error}
      label={label}
      name={name}
      placeholder={placeholder}
      rows={5}
      type={type}
      {...rest}
    />
  </Container>
)

Input.defaultProps = {
  className: '',
  error: null,
  label: '',
  placeholder: '',
  type: 'textarea',
}

Input.propTypes = {
  className: PropTypes.string,
  error: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  type: PropTypes.string,
}

Input.displayName = 'Input'

export default Input
