import React from 'react'
import { Redirect } from 'react-router-dom'

import { ADMIN_PATHS, ADMIN_ROOT } from 'Constants/paths'

import * as AdminPages from 'Containers/Pages/Admin'

const routes = (viewer) => {
  const { type } = viewer

  const paths = [
    /* Admin */
    {
      path: ADMIN_ROOT,
      exact: true,
      component: () => <Redirect to={ADMIN_PATHS.ADMINS} />,
    },
    {
      path: ADMIN_PATHS.PROJECTS,
      exact: true,
      component: AdminPages.Projects,
    },
    {
      path: ADMIN_PATHS.PROJECT(),
      exact: true,
      component: AdminPages.Project,
    },
    {
      path: ADMIN_PATHS.PROJECT_CREATE,
      exact: true,
      component: AdminPages.Project,
    },
    {
      path: ADMIN_PATHS.DASHBOARD,
      exact: true,
      component: AdminPages.Dashboard,
    },
    {
      path: ADMIN_PATHS.ADMINS,
      exact: true,
      component: AdminPages.Admins,
    },
    {
      path: ADMIN_PATHS.ADMIN(),
      exact: true,
      component: AdminPages.Admin,
    },
    {
      path: ADMIN_PATHS.INVITATIONS,
      exact: true,
      component: AdminPages.Invitations,
    },
    {
      path: ADMIN_PATHS.LABOR,
      exact: true,
      component: AdminPages.Labors,
    },
    {
      path: ADMIN_PATHS.LABOR_DETAILS(),
      exact: true,
      component: AdminPages.Labor,
    },
    {
      path: ADMIN_PATHS.REPORTS,
      exact: true,
      component: AdminPages.Reports,
    },
    {
      path: ADMIN_PATHS.CLIENTS,
      exact: true,
      component: AdminPages.Clients,
    },
    {
      path: ADMIN_PATHS.SUBCONTRACTOR_PROVIDERS,
      exact: true,
      component: AdminPages.SubcontractorProviders,
    },
    {
      path: ADMIN_PATHS.SUBCONTRACTOR_PROVIDER(),
      exact: true,
      component: AdminPages.SubcontractorProvider,
    },
  ]

  if (type === 'superAdmins') {
    paths.push({
      path: ADMIN_PATHS.ROLES,
      exact: true,
      component: AdminPages.Roles,
    })
  }

  return paths
}

export default routes
