import { NavLink } from 'react-router-dom'

import styled, { css } from 'styled-components'
import { mapToTheme } from 'styled-map'
import { themeGet } from '@styled-system/theme-get'

import { Flex, Text } from 'rebass'

export const Container = styled(Flex)`
  height: 100vh;
  width: ${mapToTheme('sizes.sidebarWidth')};
  flex-direction: row;
  position: fixed;
  left: 0;
  z-index: 300;
  order: 1;
  flex: initial;
  background: ${themeGet('colors.primary')};
  transition: height 0.25s ease-in-out;
`

export const Bar = styled(Flex)`
  -webkit-box-align: center;
  align-items: center;
  color: rgb(222, 235, 255);
  box-sizing: border-box;
  flex-direction: column;
  justify-content: space-between;
  width: ${mapToTheme('sizes.sidebarWidth')};
`

export const Menu = styled(Flex)`
  width: 100%;
  height: 100%;
  flex-direction: column;
  justify-content: space-between;
`

export const Logo = styled(Flex).attrs({
  pl: 5,
  mb: 5,
})`
  background: ${themeGet('colors.navy')};
  height: ${themeGet('sizes.header')};
  justify-content: center;
  align-items: center;
  position: relative;

  svg {
    position: absolute;
    left: 32px;
  }
`

export const Label = styled(Text).attrs({ as: 'span' })`
  font-size: 16px;
`

export const StyledNav = styled(NavLink)`
  width: ${mapToTheme('sizes.sidebarWidth')};
  height: ${themeGet('sizes.header')};
  display: flex;
  justify-content: center;
  align-items: flex-start;
  position: relative;
  flex-direction: column;
  color: ${themeGet('colors.white')};
  padding-left: 64px;
  text-decoration: none;

  svg {
    position: absolute;
    left: 32px;
  }

  ${(props) =>
    props.disabled &&
    css`
      opacity: 0.5;
      pointer-events: none;
    `};

  &.active {
    &:after {
      content: '';
      position: absolute;
      left: 0;
      height: 48px;
      top: 8px;
      width: 4px;
      background: ${themeGet('colors.secondary')};
    }
  }

  .icon {
    width: 40px;
    height: 40px;
    border-radius: 20px;
    background-color: white;
  }
`

export const Top = styled(Flex)`
  flex-direction: column;
`

export const Bottom = styled(Flex)`
  flex-direction: column;
`
