import React from 'react'
import { FormSpy } from 'react-final-form'

import UnsavedChangesChecker from './UnsavedChangesChecker'

export default (props) => (
  <FormSpy
    {...props}
    component={UnsavedChangesChecker}
    subscription={{
      initialValues: true,
      values: true,
      submitting: true,
      submitSucceeded: true,
    }}
  />
)
