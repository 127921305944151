import apiCall from 'Services/Api'

import { createAsyncAction } from 'Store/utils'

export const LOAD_MILITARY_SERVICE_TYPES = createAsyncAction(
  'onboarding/military/LOAD_MILITARY_SERVICE_TYPES',
)

export const loadMilitaryServiceTypes = () =>
  apiCall({
    endpoint: '/military_service_types',
    types: LOAD_MILITARY_SERVICE_TYPES,
  })
