import React, { PureComponent } from 'react'
import { Form } from 'react-final-form'
import { toast } from 'react-toastify'
import PropTypes from 'prop-types'

import { Box, Flex } from 'rebass'
import validate from 'validate.js'

import get from 'lodash/get'

import { Forms, Toast } from 'Components/Blocks'
import { Button, Modal, Title } from 'Components/UI'

import { presenceFieldConstraint } from 'Constants/constraints'
import { PROJECT_KIND_LABELS, PROJECT_KINDS } from 'Constants/types'

import { Error } from '../styles'

class CreateRole extends PureComponent {
  state = { error: null, initialValues: {} }

  componentDidMount() {
    const { isEdit, selectedRole } = this.props

    if (isEdit) {
      this.setState({
        initialValues: {
          ...selectedRole,
          kind: this.kindToFormValues(selectedRole.kind),
        },
      })
    }
  }

  kindToFormValues = (kind) =>
    kind === PROJECT_KINDS.commercial_jobs
      ? {
          value: PROJECT_KINDS.commercial_jobs,
          label: PROJECT_KIND_LABELS.commercial_jobs,
        }
      : {
          value: PROJECT_KINDS.emergency_services,
          label: PROJECT_KIND_LABELS.emergency_services,
        }

  validate = (values) =>
    validate(values, {
      ...presenceFieldConstraint('name'),
      ...presenceFieldConstraint('kind'),
    })

  handleCreateRole = async (values) => {
    const {
      onCreateRole,
      onUpdateRole,
      onCallback,
      isEdit,
      selectedRole,
    } = this.props

    this.setState({ error: null })

    const kind = get(values, 'kind.value')
    const id = get(selectedRole, 'id')

    const result = isEdit
      ? await onUpdateRole({
          ...values,
          kind,
          id,
        })
      : await onCreateRole({
          ...values,
          kind,
        })

    if (result.ok) {
      const msg = isEdit
        ? 'Role updated successfully'
        : 'Role created successfully'
      toast.success(<Toast heading={msg} type="success" />)
      onCallback()
    } else {
      this.setState({
        error: 'Something goes wrong. Please try again later...',
      })
    }
  }

  handleCallback = () => {
    const { onCallback } = this.props
    this.setState({ error: null })
    onCallback()
  }

  renderForm = ({ handleSubmit, invalid }) => {
    const { error } = this.state
    const { onCallback, isEdit } = this.props

    return (
      <Box as="form" p={4} width={430} onSubmit={handleSubmit}>
        <Title.H2 color="brownishGray" mb={4} mt={4} textAlign="center">
          {isEdit ? 'Edit' : 'Create'} New Role
        </Title.H2>

        <Flex>
          <Box pr={3} width={[1]}>
            <Forms.Input
              label="Role Name"
              name="name"
              placeholder="Enter Role Name"
            />

            <Forms.Select
              label="Project Type"
              name="kind"
              options={[
                {
                  value: PROJECT_KINDS.commercial_jobs,
                  label: PROJECT_KIND_LABELS[PROJECT_KINDS.commercial_jobs],
                },
                {
                  value: PROJECT_KINDS.emergency_services,
                  label: PROJECT_KIND_LABELS[PROJECT_KINDS.emergency_services],
                },
              ]}
              placeholder="Choose project type"
              selectProps={{
                isSearchable: false,
                placeholder: 'Choose project type',
              }}
            />
          </Box>
        </Flex>

        {error && <Error>{error}</Error>}

        <Flex alignItems="center" justifyContent="flex-end" mt={4}>
          <Button default mr={2} onClick={onCallback}>
            Close
          </Button>

          <Button disabled={invalid} secondary type="submit">
            {isEdit ? 'Edit' : 'Create'} Role
          </Button>
        </Flex>
      </Box>
    )
  }

  render() {
    const { isOpen } = this.props
    const { initialValues } = this.state

    return (
      <Modal isOpen={isOpen} onCallback={this.handleCallback}>
        <Modal.Close onClick={this.handleCallback} />
        <Form
          initialValues={initialValues}
          render={this.renderForm}
          validate={this.validate}
          onSubmit={this.handleCreateRole}
        />
      </Modal>
    )
  }
}

CreateRole.defaultProps = {
  isEdit: false,
  selectedRole: {},
}

CreateRole.propTypes = {
  isEdit: PropTypes.bool,
  isOpen: PropTypes.bool.isRequired,
  selectedRole: PropTypes.object,
  onCallback: PropTypes.func.isRequired,
  onCreateRole: PropTypes.func.isRequired,
  onUpdateRole: PropTypes.func.isRequired,
}

export default CreateRole
