import { connect } from 'react-redux'

import { createStructuredSelector } from 'reselect'

import {
  activateSubcontractorProvider,
  deactivateSubcontractorProvider,
} from 'Store/Actions/admin/subcontractor-providers'
import { getSubcontractorProviderById } from 'Store/Selectors/admin/subcontractor-providers'

import SubcontractorProviderStatus from './SubcontractorProviderStatus'

export default connect(
  createStructuredSelector({
    subcontractorProvider: (state, props) =>
      getSubcontractorProviderById(props.subcontractorProviderId)(state, props),
  }),
  {
    onActivateSubcontractorProvider: activateSubcontractorProvider,
    onDeactivateSubcontractorProvider: deactivateSubcontractorProvider,
  },
)(SubcontractorProviderStatus)
