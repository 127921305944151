import styled, { css } from 'styled-components'
import { themeGet } from '@styled-system/theme-get'

import { Box, Flex } from 'rebass'

import { magnifyGlyph, triangleDownGlyph } from 'Assets/Svg'

import { Icon, Select } from 'Components/UI'

export const Container = styled(Box).attrs({
  mt: 3,
  mb: 0,
})`
  border-bottom: 1px solid ${themeGet('colors.veryLightGray')};
`

export const Search = styled(Box)`
  position: relative;
`

export const SelectList = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const AvatarName = styled(Box)`
  display: flex;
  align-items: center;
  p {
    margin-left: 8px;
  }
`

export const SearchIcon = styled(Icon).attrs({
  glyph: magnifyGlyph,
  size: 18,
})`
  position: absolute;
  fill: ${themeGet('colors.secondary')};
  z-index: 15;
  left: 9px;
  top: 12px;
`

export const StyledSelect = styled(Select)`
  position: relative;
  background: ${themeGet('colors.clear')} !important;

  :before {
    content: 'Search by Labor Name or ID';
    position: absolute;
    top: 11px;
    left: 32px;
    z-index: 0;
    font-size: 16px;
    color: ${themeGet('colors.brownGray')};
    user-select: none;
  }

  :focus-within {
    &:before {
      display: none;
    }
  }

  .react-select__container {
    background: ${themeGet('colors.clear')} !important;
  }

  .react-select__control {
    background: ${themeGet('colors.clear')} !important;
  }
`

export const ValueWrapper = styled(Box).attrs({
  pl: 3,
})``

export const Labors = styled(Flex).attrs({
  width: 1,
  mt: 3,
})`
  flex-wrap: wrap;
`

export const Header = styled(Box).attrs({ fontSize: 3, mb: 3 })`
  cursor: pointer;
`

export const TriangleIcon = styled(Icon).attrs({
  glyph: triangleDownGlyph,
  size: 16,
  ml: 2,
  mt: '-2px',
})`
  fill: ${themeGet('colors.secondary')};
  transition: all ease 0.17s;

  ${(props) =>
    props.opened &&
    css`
      transform: rotate(180deg);
    `}
`

export const Collapse = styled(Box)`
  ${(props) =>
    props.isOpened
      ? css`
          max-height: auto;
          overflow: visible;
        `
      : css`
          max-height: 0;
          overflow: hidden;
        `};

  transition: all 0.25s ease-in-out;
`
