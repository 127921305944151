import { denormalize } from 'rapidux'
import { createSelector } from 'reselect'

import get from 'lodash/get'

import { isOnboarded } from 'Services/Utils'

import { getData } from './data'
import { getAccess, getAccessToken } from './persist'

export const getState = (state) => state.viewer

export const getViewerId = createSelector(getState, (state) => state.id)
export const getViewerType = createSelector(getState, (state) => state.type)

const appendValuesToViewer = (viewer, { type, ...rest }) => ({
  ...viewer,
  ...rest,
  type,
  isOnboarded: isOnboarded(viewer, type),
})

export const getViewer = createSelector(
  getData,
  getViewerId,
  getViewerType,
  (data, viewerId, viewerType) =>
    viewerId && viewerType
      ? appendValuesToViewer(denormalize(data, viewerType, viewerId), {
          type: viewerType,
          isAuthenticated: true,
        })
      : {},
)

export const getError = createSelector(getState, (state) => state.error)

export const getAuthorizationHeader = createSelector(getAccessToken, (access) =>
  access ? `Bearer ${access}` : null,
)

export const getIsAuthenticated = createSelector(
  getAccess,
  (access) => access !== null,
)

export const getIsDataLoaded = createSelector(
  getState,
  (state) => state.isDataLoaded,
)

export const getUsers = createSelector(getData, (data) =>
  denormalize(data, 'users'),
)

export const getPendingPrimaryPhoneNumberId = createSelector(
  getViewer,
  (viewer) => get(viewer, 'profile.pendingPrimaryPhoneNumber.id'),
)
