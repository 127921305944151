import ReactTable from 'react-table'

import styled, { css } from 'styled-components'
import { justifyContent, space } from 'styled-system'
import { themeGet } from '@styled-system/theme-get'

import { Flex } from 'rebass'

import Icon from '../Icon'

export const Sortable = styled(Flex).attrs({
  ml: 1,
})``

export const IconAsc = styled(Icon)`
  transform: rotate(180deg);
  fill: ${themeGet('colors.brownGray')};
`

export const IconDesc = styled(Icon)`
  margin-left: -3px;
  margin-top: 0.5px;
  fill: ${themeGet('colors.brownGray')};
`

export const DataTable = styled(ReactTable).attrs({
  mb: 4,
})`
  border: none !important;

  .rt-table {
    overflow: visible;
    min-height: 200px;

    .rt-thead {
      box-shadow: none !important;
      background: ${themeGet('colors.veryLightGray')};
      border-bottom: 1px solid ${themeGet('colors.veryLightPink')};
      padding-top: 8px;
      padding-bottom: 8px;
      font-weight: bold;

      &.-filters {
        padding: 0;

        input {
          margin: 0;
        }
      }

      .rt-th {
        border-right: none !important;

        &.-sort-desc {
          box-shadow: none !important;

          ${IconDesc} {
            fill: ${themeGet('colors.secondary')};
          }
        }

        &.-sort-asc {
          box-shadow: none !important;

          ${IconAsc} {
            fill: ${themeGet('colors.secondary')};
          }
        }
      }
    }

    .rt-tbody {
      overflow: visible;
      border-bottom: 1px solid ${themeGet('colors.lightGray')} !important;

      .rt-td {
        min-height: 52px;
        padding: 10px 5px;
        border-right: none !important;
        display: table-cell;
        vertical-align: middle;
      }
    }

    .rt-tfoot {
      box-shadow: none !important;

      .rt-td {
        border-right: none !important;
      }
    }
  }

  ${space};
`

export const ReportTable = styled(ReactTable).attrs({
  mb: 4,
})`
  border-color: ${themeGet('colors.veryLightPink')} !important;
  overflow-y: auto;

  .rt-table {
    overflow: visible;
    min-height: 200px;

    .rt-thead {
      box-shadow: none !important;
      background: ${themeGet('colors.veryLightGray')};
      border-bottom: 1px solid ${themeGet('colors.veryLightPink')};
      padding-top: 8px;
      padding-bottom: 8px;
      font-weight: bold;

      &.-filters {
        padding: 0;

        input {
          margin: 0;
        }
      }

      .rt-th {
        padding: ${themeGet('space.3')}px;
        display: flex;
        justify-content: center;
        align-items: center;

        &.-sort-desc {
          box-shadow: none !important;

          ${IconDesc} {
            fill: ${themeGet('colors.secondary')};
          }
        }

        &.-sort-asc {
          box-shadow: none !important;

          ${IconAsc} {
            fill: ${themeGet('colors.secondary')};
          }
        }
      }
    }

    .rt-tbody {
      overflow: visible;
      border-bottom: 1px solid ${themeGet('colors.veryLightPink')} !important;

      .rt-td {
        border-color: ${themeGet('colors.veryLightPink')};
        min-height: 52px;
        padding: 10px 5px;
        display: table-cell;
        vertical-align: middle;
        text-align: center;
      }
    }

    .rt-tfoot {
      box-shadow: none !important;
      border-color: ${themeGet('colors.veryLightPink')};

      .rt-td {
        border-color: ${themeGet('colors.veryLightPink')};
        padding: ${themeGet('space.3')}px;
        display: flex;
        justify-content: center;
        align-items: center;
        min-height: 80px;
        font-weight: bold;
      }
    }
  }

  ${(props) =>
    props.noMargin &&
    css`
      margin-bottom: 0px !important;
      border-bottom: 0px !important;
    `};

  ${(props) =>
    props.smallPaddings &&
    css`
      .rt-table {
        min-height: 20px;
        .rt-thead {
          .rt-th {
            padding: 5px;
          }
        }
        .rt-tfoot {
          .rt-td {
            min-height: 0;
          }
        }
      }
    `};

  ${space};
`

export const SubComponentTable = styled(ReportTable)`
  border: none !important;
  margin-bottom: 0 !important;

  .rt-table {
    min-height: 50px;

    .rt-thead {
      display: none !important;
    }

    .rt-body {
      border: none !important;
    }
  }
`

export const HeaderCellContainer = styled(Flex)`
  justify-content: flex-start;
  align-items: center;

  ${justifyContent};
`
