import { createSelector } from 'reselect'

import { transformToOnboarding } from 'Services/Entities/UserProfile'

import { getViewer } from 'Store/Selectors/viewer'

export const getOnboardingProfile = createSelector(getViewer, ({ profile }) =>
  transformToOnboarding(profile),
)

export default {}
