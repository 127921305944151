import apiCall from 'Services/Api'

import { createAsyncAction } from 'Store/utils'

export const LOAD_ETHNICITIES = createAsyncAction(
  'onboarding/race/LOAD_ETHNICITIES',
)

export const loadEthnicities = () =>
  apiCall({
    endpoint: '/ethnicities',
    types: LOAD_ETHNICITIES,
  })
