import styled from 'styled-components'
import { mapToTheme } from 'styled-map'

import { Box, Flex } from 'rebass'

export const LaborListWrap = styled(Box)`
  width: 700px;
  padding: 0 50px;
`

export const LaborList = styled(Box)`
  width: 100%;
  flex-wrap: wrap;
`
export const ListItem = styled.div`
  width: 100%;
  display: flex;
  border-bottom: 1px solid #e3e3e6;
  padding-bottom: 15px;
  align-items: center;
  justify-content: space-between;
`

export const LaborInfo = styled(Flex)`
  font-size: 16px;
  color: #1d2a2f;
  display: flex;
  align-items: center;
`
export const LaborFullname = styled(LaborInfo)`
  margin-left: 10px;
  justify-content: flex-start;
  color: ${mapToTheme('links.color')};
`

export default {}
