import React, { PureComponent } from 'react'
import { Form } from 'react-final-form'
import { toast } from 'react-toastify'
import PropTypes from 'prop-types'

import { Box } from 'rebass'
import validate from 'validate.js'

import get from 'lodash/get'
import head from 'lodash/head'

import { onboardingPhoneGlyp } from 'Assets/Svg'

import { Forms, Toast } from 'Components/Blocks'
import PhoneVerification from 'Components/Blocks/Admin/Modals/ChangePhoneNumber/PhoneVerification'
import { Button, Icon, Modal, Text, Title } from 'Components/UI'

import {
  phoneNumberConstraint,
  presenceFieldConstraint,
} from 'Constants/constraints'
import { PHONE_MASK } from 'Constants/masks'
import { STEP_NAMES } from 'Constants/onboarding'

import { Error, FormContainer } from './styles'

const STEPS = {
  PHONE: 'PHONE',
  PHONE_VERIFICATION: 'PHONE_VERIFICATION',
}

class ChangePhoneNumber extends PureComponent {
  state = {
    error: null,
    phoneNumberId: null,
    currentStep: STEPS.PHONE,
  }

  validate = (values) => {
    const { currentStep } = this.state

    if (currentStep === STEPS.PHONE) {
      return validate(values, {
        ...presenceFieldConstraint('phoneNumber'),
        ...phoneNumberConstraint('phoneNumber', true),
      })
    }
    return validate(values, {
      ...presenceFieldConstraint('verificationCode'),
    })
  }

  handleChangeNumber = async (values) => {
    const {
      onCreatePhoneNumber,
      onSendVerificationCode,
      onVerifyPhone,
      userId,
      onCallback,
    } = this.props
    const { phoneNumberId } = this.state

    const { currentStep } = this.state

    if (currentStep === STEP_NAMES.PHONE) {
      const {
        ok: phoneNumberCreated,
        error: phoneNumberError,
        payload,
      } = await onCreatePhoneNumber({
        phoneNumber: values.phoneNumber,
        laborId: userId,
      })

      if (phoneNumberCreated) {
        const numberId = get(
          head(Object.values(payload.data.profilePhoneNumbers)),
          'id',
        )

        this.setState(
          {
            phoneNumberId: numberId,
          },
          async () => {
            const {
              ok: verificationCodeSent,
              error: verificationCodeError,
            } = await onSendVerificationCode({
              laborId: userId,
              phoneNumberId: numberId,
            })

            if (verificationCodeSent) {
              this.setState({ currentStep: STEPS.PHONE_VERIFICATION })
              toast.success(
                <Toast
                  heading="Verification code sent to your phone"
                  type="success"
                />,
              )
            }

            if (verificationCodeError) {
              if (verificationCodeError.status === 422)
                toast.error(
                  <Toast heading="Phone number is invalid" type="error" />,
                )
              else
                toast.error(
                  <Toast
                    heading="Something went wrong"
                    text="Please try again"
                    type="error"
                  />,
                )
            }
          },
        )
      }

      if (phoneNumberError) {
        if (phoneNumberError.status === 409)
          toast.error(
            <Toast
              heading="Another user has verified this phone number"
              type="error"
            />,
          )
        else
          toast.error(
            <Toast
              heading="Something went wrong"
              text="Please try again"
              type="error"
            />,
          )
      }
    } else if (currentStep === STEPS.PHONE_VERIFICATION) {
      const { ok, error } = await onVerifyPhone({
        laborId: userId,
        phoneNumberId,
        verificationCode: values.verificationCode,
      })

      if (ok) {
        onCallback()

        toast.success(
          <Toast heading="Phone number successfully updated" type="success" />,
        )
      }

      if (error) {
        if (error.status === 422)
          toast.error(
            <Toast heading="Verification code is incorrect" type="error" />,
          )
        else if (error.status === 409)
          toast.error(
            <Toast
              heading="Another user has verified this phone number"
              type="error"
            />,
          )
        else
          toast.error(
            <Toast
              heading="Something went wrong"
              text="Please try again"
              type="error"
            />,
          )
      }
    }
  }

  handleCallback = () => {
    const { onCallback } = this.props
    this.setState({ error: null })
    onCallback()
  }

  renderForm = ({ handleSubmit, invalid }) => {
    const { error, currentStep, phoneNumberId } = this.state
    const { onSendVerificationCode, userId } = this.props

    return (
      <FormContainer onSubmit={handleSubmit}>
        <Icon glyph={onboardingPhoneGlyp} size={30} />
        {currentStep === STEPS.PHONE ? (
          <>
            <Title.H2 mb={5} mt={3} textAlign="center">
              Phone validation
            </Title.H2>

            <Text color="brownishGray" mb={4} textAlign="center" width={1}>
              Confirm your phone number to receive verification code
            </Text>

            <Box width={1}>
              <Forms.Input
                label="Phone Number"
                mask={PHONE_MASK}
                name="phoneNumber"
                placeholder=""
              />
            </Box>
          </>
        ) : (
          <PhoneVerification
            phoneNumberId={phoneNumberId}
            userId={userId}
            onSendVerificationCode={onSendVerificationCode}
          />
        )}

        <Button disabled={invalid} mt={4} secondary type="submit" width={1}>
          Verify Phone
        </Button>

        {error && <Error>{error}</Error>}
      </FormContainer>
    )
  }

  render() {
    const { isOpen } = this.props

    return (
      <Modal isOpen={isOpen} onCallback={this.handleCallback}>
        <Modal.Close onClick={this.handleCallback} />
        <Form
          render={this.renderForm}
          validate={this.validate}
          onSubmit={this.handleChangeNumber}
        />
      </Modal>
    )
  }
}

ChangePhoneNumber.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  userId: PropTypes.string.isRequired,
  onCallback: PropTypes.func.isRequired,
  onCreatePhoneNumber: PropTypes.func.isRequired,
  onSendVerificationCode: PropTypes.func.isRequired,
  onVerifyPhone: PropTypes.func.isRequired,
}

export default ChangePhoneNumber
