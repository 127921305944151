import { createFields, createReducerHandlers } from 'rapidux'

import { LOAD_MILITARY_SERVICE_TYPES } from 'Store/Actions/onboarding/military'
import { LOG_OUT } from 'Store/Actions/viewer'
import { createReducer } from 'Store/utils'

const initialState = {
  ...createFields('militaryServiceTypes'),
}
const handlers = {
  ...createReducerHandlers(
    'militaryServiceTypes',
    LOAD_MILITARY_SERVICE_TYPES,
    {
      withReplace: false,
    },
  ),

  [LOG_OUT]: (state) => state.merge(initialState),
}

export default createReducer(initialState, handlers)
