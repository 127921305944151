import React, { useState } from 'react'
import { toast } from 'react-toastify'
import PropTypes from 'prop-types'

import { DateTime } from 'luxon'
import { Box, Flex } from 'rebass'

import get from 'lodash/get'
import has from 'lodash/has'

import { Toast } from 'Components/Blocks'
import SwitchToDirect from 'Components/Blocks/Admin/Modals/SwitchToDirect'
import SwitchToSubContractor from 'Components/Blocks/Admin/Modals/SwitchToSubContractor'
import { Loader, MenuButton, Text } from 'Components/UI'

import { LABOR_KIND_NAMES } from 'Constants/detailsPage'
import { LABOR_KIND_TYPES, USER_TYPES } from 'Constants/types'

import { getProfileFullName, getProfilePhone } from 'Services/Utils'

import { Container, Content, MenuWrapper, Photo, Status } from './styles'

const MainInfo = ({ data, onClickActivate, onClickDeactivate, viewer }) => {
  const [isDirectModalOpen, toggleDirectModal] = useState(false)
  const [isSubContractorModalOpen, toggleSubContractorModal] = useState(false)

  if (!data.isLoaded) return <Loader />

  const handleActivateDeactivate = async (id) => {
    const { error } =
      get(data, 'entity.status') === 'Deactivated'
        ? await onClickActivate(id)
        : await onClickDeactivate(id)

    if (error) {
      if (get(error, 'status') === 422) {
        toast.error(
          <Toast
            heading={`User cannot be ${
              get(data, 'entity.status') === 'Deactivated'
                ? 'activated'
                : 'deactivated'
            }`}
            type="error"
          />,
        )
      } else {
        toast.error(
          <Toast
            heading="Something went wrong"
            text={get(error, 'message')}
            type="error"
          />,
        )
      }
    }
  }

  const getActions = () => {
    const laborKind = get(data, 'entity.laborKind')
    const viwerType = get(viewer, 'type')
    const isAdmin =
      viwerType === USER_TYPES.admins || viwerType === USER_TYPES.superAdmins

    const actionsOptions = [
      // {
      //   key: 'edit',
      //   glyph: actionEditGlyph,
      //   label: 'Edit',
      //   onSelect: () => null,
      // },
      {
        key: 'activateDeactivate',
        glyph: null,
        entity: null,
        label:
          get(data, 'entity.status') === 'Deactivated'
            ? 'Activate'
            : 'Deactivate',
        onSelect: handleActivateDeactivate,
      },
    ]

    // Show only for Admins/superadmins
    if (isAdmin) {
      if (laborKind === LABOR_KIND_TYPES.subcontractor) {
        actionsOptions.push(
          {
            key: 'switchToDirect',
            glyph: null,
            entity: null,
            label: 'Switch to Direct Employee',
            onSelect: () => toggleDirectModal(!isDirectModalOpen),
          },
          {
            key: 'switchToAnotherSubContractor',
            glyph: null,
            entity: null,
            label: 'Switch to another Sub-Contractor Company Provider',
            onSelect: () => toggleSubContractorModal(!isSubContractorModalOpen),
          },
        )
      }
      if (laborKind === LABOR_KIND_TYPES.direct) {
        actionsOptions.push({
          key: 'switchToSubcontractor',
          glyph: null,
          entity: null,
          label: 'Switch to Sub-Contractor',
          onSelect: () => toggleSubContractorModal(!isSubContractorModalOpen),
        })
      }
    }

    return actionsOptions
  }

  const isDirectEmployee =
    get(data, 'entity.laborKind') === LABOR_KIND_TYPES.direct

  return (
    <Container>
      {isDirectModalOpen && (
        <SwitchToDirect
          data={data}
          isOpen={isDirectModalOpen}
          onCallback={() => toggleDirectModal(!isDirectModalOpen)}
        />
      )}
      {isSubContractorModalOpen && (
        <SwitchToSubContractor
          data={data}
          isOpen={isSubContractorModalOpen}
          type={isDirectEmployee ? 'switch' : 'new'}
          onCallback={() => toggleSubContractorModal(!isSubContractorModalOpen)}
        />
      )}

      <MenuWrapper mt={3}>
        <MenuButton actions={getActions()} width={250} />
      </MenuWrapper>

      <Box width={[1, 1, 1 / 2, 1 / 3]}>
        <Photo
          src={get(data, 'entity.profile.personalPhoto.content.medium.url')}
        />
      </Box>

      <Content ml={3} width={[1, 1, 1 / 2, 2 / 3]}>
        <Flex>
          <Text mr={1}>Full name:</Text>

          <Text fontWeight="bold">
            {getProfileFullName(
              get(data, 'entity.profile.firstName'),
              get(data, 'entity.profile.lastName'),
            )}
          </Text>
        </Flex>

        <Flex>
          <Text mr={1}>Email:</Text>

          <Text
            as="a"
            fontWeight="bold"
            href={`mailto:${get(data, 'entity.profile.email')}`}
          >
            {get(data, 'entity.profile.email')}
          </Text>
        </Flex>

        {has(data, 'entity.laborKind') && (
          <Flex>
            <Text mr={1}>Type:</Text>

            <Text fontWeight="bold">
              {get(
                LABOR_KIND_NAMES,
                get(data, 'entity.laborKind'),
                get(data, 'entity.laborKind'),
              )}
            </Text>
          </Flex>
        )}

        {get(data, 'entity.subcontractorProvider') &&
          get(data, 'entity.laborKind') === LABOR_KIND_TYPES.subcontractor && (
            <Flex>
              <Text mr={1}>Sub-Contractor Company Provider Name:</Text>

              <Text fontWeight="bold">
                {get(data, 'entity.subcontractorProvider.name')}
              </Text>
            </Flex>
          )}

        <Flex>
          <Text mr={1}>Phone number:</Text>

          <Text fontWeight="bold">
            {getProfilePhone(get(data, 'entity.profile'))}
          </Text>
        </Flex>

        {has(data, 'entity.laborKind') && (
          <Flex>
            <Text mr={1}>Last working day:</Text>

            <Text fontWeight="bold" />
          </Flex>
        )}

        <Flex>
          <Text mr={1}>Date submitted:</Text>

          <Text fontWeight="bold">
            {DateTime.fromISO(get(data, 'entity.createdAt')).toFormat(
              'LL/dd/yyyy',
            )}
          </Text>
        </Flex>

        <Flex>
          <Text mr={1}>Status:</Text>

          <Status>{get(data, 'entity.status')}</Status>
        </Flex>
      </Content>
    </Container>
  )
}

MainInfo.propTypes = {
  data: PropTypes.object.isRequired,
  viewer: PropTypes.object.isRequired,
  onClickActivate: PropTypes.func.isRequired,
  onClickDeactivate: PropTypes.func.isRequired,
}

export default MainInfo
