import React, { PureComponent } from 'react'
import { Form } from 'react-final-form'
import { toast } from 'react-toastify'
import PropTypes from 'prop-types'

import { Box } from 'rebass'
import validate from 'validate.js'

import get from 'lodash/get'
import pickBy from 'lodash/pickBy'

import { Toast } from 'Components/Blocks'
import ReportSubcontractorProvidersSelector from 'Components/Blocks/Admin/ReportSubcontractorProvidersSelector'
import { Modal, Text, Title } from 'Components/UI'

import { presenceFieldConstraint } from 'Constants/constraints'
import { LABOR_KIND_TYPES } from 'Constants/types'

import {
  ButtonContainer,
  Container,
  ContentContainer,
  Error,
  StyledButton,
  SubTitle,
} from './styles'

class SwitchToSubContractor extends PureComponent {
  state = {
    error: null,
  }

  validate = (values) =>
    validate(values, {
      ...presenceFieldConstraint('subcontractorProvider'),
    })

  handleSubmit = async (values) => {
    const { data, onSwitch, onCallback } = this.props
    const laborId = get(data, 'entity.id')
    const { subcontractorProvider } = values

    const params = {
      kind: LABOR_KIND_TYPES.subcontractor,
      laborId,
      subcontractorProviderId: subcontractorProvider.value,
    }

    const result = await onSwitch(params)

    if (result.ok) {
      toast.success(
        <Toast
          heading="User successfully switched to Sub-Contractor"
          type="success"
        />,
      )
      onCallback()
    } else {
      this.setState({
        error: 'Something goes wrong. Please try again later...',
      })
    }
  }

  handleCallback = () => {
    const { onCallback } = this.props
    this.setState({ error: null })
    onCallback()
  }

  getInitialValues = () => {
    const { data } = this.props
    const isDirect = get(data, 'entity.laborKind') === LABOR_KIND_TYPES.direct

    if (isDirect) return {}

    const subContractorProviderCompanyName = get(
      data,
      'entity.subcontractorProvider.name',
    )
    const subContractorProviderCompanyId = get(
      data,
      'entity.subcontractorProvider.id',
    )

    return pickBy({
      subcontractorProvider: subContractorProviderCompanyName
        ? {
            label: subContractorProviderCompanyName,
            value: subContractorProviderCompanyId,
          }
        : undefined,
    })
  }

  renderForm = ({ handleSubmit, invalid }) => {
    const { error } = this.state
    const { data, type } = this.props
    const subContractorProviderCompany = get(
      data,
      'entity.subcontractorProvider.name',
    )
    const isDirect = get(data, 'entity.laborKind') === LABOR_KIND_TYPES.direct

    return (
      <Container>
        <Title.H2
          color="brownishGray"
          mb={4}
          mt={1}
          textAlign="center"
          width={1}
        >
          Switching Sub-Contractor Company Provider
        </Title.H2>

        {subContractorProviderCompany && !isDirect && (
          <ContentContainer>
            <Text>Current Sub-Contractor</Text>
            <br />
            <Text>
              Company Provider Name: <b>{subContractorProviderCompany}</b>
            </Text>
          </ContentContainer>
        )}

        <Box ml={3} width={1}>
          <ContentContainer>
            <SubTitle mb={3}>
              {type === 'switch'
                ? 'Switch to Sub-Contractor Company Provider'
                : 'New Sub-Contractor Company Provider'}
            </SubTitle>
          </ContentContainer>

          <ReportSubcontractorProvidersSelector
            label=""
            name="subcontractorProvider"
          />
        </Box>

        <ButtonContainer>
          <StyledButton
            buttonType="grey"
            mt={4}
            secondary
            width={1 / 3}
            onClick={this.handleCallback}
          >
            Cancel
          </StyledButton>

          <StyledButton
            disabled={invalid}
            mt={4}
            secondary
            width={1 / 3}
            onClick={handleSubmit}
          >
            Switch
          </StyledButton>
        </ButtonContainer>

        {error && <Error>{error}</Error>}
      </Container>
    )
  }

  render() {
    const { isOpen } = this.props

    return (
      <Modal isOpen={isOpen} onCallback={this.handleCallback}>
        <Modal.Close onClick={this.handleCallback} />
        <Form
          initialValues={this.getInitialValues()}
          render={this.renderForm}
          validate={this.validate}
          onSubmit={this.handleSubmit}
        />
      </Modal>
    )
  }
}

SwitchToSubContractor.propTypes = {
  data: PropTypes.object.isRequired,
  isOpen: PropTypes.bool.isRequired,
  type: PropTypes.string.isRequired,
  onCallback: PropTypes.func.isRequired,
  onSwitch: PropTypes.func.isRequired,
}

export default SwitchToSubContractor
