import React, { PureComponent } from 'react'
import { Field } from 'react-final-form'
import PropTypes from 'prop-types'

import debounce from 'debounce-promise'
import { denormalize } from 'rapidux'

import get from 'lodash/get'
import map from 'lodash/map'
import reduce from 'lodash/reduce'

import { Select } from 'Components/Blocks/Forms'

const entityToOption = (entity) => ({
  id: get(entity, 'id'),
  value: get(entity, 'id'),
  label: get(entity, 'name'),
})

class FieldRenderer extends PureComponent {
  loadOptions = debounce(async (inputValue) => {
    const { onSearchClients } = this.props

    const {
      payload: { data },
    } = await onSearchClients(inputValue, { state: { neq: 'deactivated' } })

    const options = denormalize(data, 'clients') || []

    return map(options, entityToOption)
  }, 500)

  get clients() {
    const { clients } = this.props

    return reduce(
      clients.entities,
      (acc, client) => {
        acc[client.id] = client
        return acc
      },
      {},
    )
  }

  render = () => {
    const { input, clients, selectProps } = this.props

    return (
      <Select
        components={{
          ClearIndicator: Select.emptyComponent,
        }}
        label="Client*"
        name={input.name}
        options={map(this.clients, entityToOption)}
        placeholder="Choose Client Name"
        selectProps={{
          async: true,
          loadOptions: this.loadOptions,
          isLoading: clients.isLoading,
          defaultOptions: true,
          ...selectProps,
        }}
        value={input.value}
        width={1}
      />
    )
  }
}

FieldRenderer.defaultProps = { selectProps: {} }

FieldRenderer.propTypes = {
  clients: PropTypes.object.isRequired,
  input: PropTypes.object.isRequired,
  selectProps: PropTypes.object,
  onSearchClients: PropTypes.func.isRequired,
}

const ClientsSelector = (props) => (
  <Field component={FieldRenderer} {...props} />
)

export default ClientsSelector
