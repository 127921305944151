import apiCall from 'Services/Api'

import { getAdminId } from 'Store/Selectors/admin/admins'
import { getLaborId } from 'Store/Selectors/admin/labors'
import { createAsyncAction } from 'Store/utils'

export const DELETE_EMPLOYEE_PACKAGE = createAsyncAction(
  'admin/employeePackage/DELETE',
)

export const deleteEmployeePackage = (id, type) => (dispatch, getState) => {
  let relationId

  if (type === 'admins') {
    relationId = getAdminId(getState())
  }

  if (type === 'labors') {
    relationId = getLaborId(getState())
  }

  return dispatch(
    apiCall({
      method: 'DELETE',
      endpoint: `/admin/employee_packages/${id}`,
      types: DELETE_EMPLOYEE_PACKAGE,
      payload: {
        type,
        relationId,
      },
    }),
  )
}

export default {}
