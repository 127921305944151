import { denormalize } from 'rapidux'
import { createSelector } from 'reselect'

import get from 'lodash/get'
import head from 'lodash/head'
import map from 'lodash/map'
import reduce from 'lodash/reduce'
import sortBy from 'lodash/sortBy'

import { getData } from '../data'

export const getState = (state) => state.admin.admins

export const getAdmins = createSelector(getState, getData, (state, data) => {
  const types = reduce(
    state.admins,
    (acc, type, id) => {
      if (type === 'admins') {
        acc.admins.push(id)
      }

      if (type === 'onboardingAdmins') {
        acc.onboardingAdmins.push(id)
      }

      return acc
    },
    {
      admins: [],
      onboardingAdmins: [],
    },
  )

  const admins = map(
    denormalize(data, 'admins', types.admins) || [],
    (item) => ({ ...item, type: 'admins' }),
  )
  const onboardingAdmins = map(
    denormalize(data, 'onboardingAdmins', types.onboardingAdmins) || [],
    (item) => ({ ...item, type: 'onboardingAdmins' }),
  )

  const paged = get(state, 'paged') || { records: [], pageCount: 1 }

  return {
    isLoaded: state.isLoaded,
    isLoading: state.isLoading,
    entities: sortBy([...admins, ...onboardingAdmins], (item) =>
      paged.records.indexOf(item.id),
    ),
    paged,
  }
})

// export const getAdmin = getEntities(getState, getData, {
//   type: 'admins',
//   field: 'admin',
//   singular: true,
// })

export const getAdmin = createSelector(getState, getData, (state, data) => {
  const types = reduce(
    state.admin,
    (acc, type, id) => {
      if (type === 'admins') {
        acc.admin.push(id)
      }

      if (type === 'onboardingAdmins') {
        acc.onboardingAdmin.push(id)
      }

      return acc
    },
    {
      admin: [],
      onboardingAdmin: [],
    },
  )

  const admins = denormalize(data, 'admins', head(types.admin)) || {}
  const onboardingAdmins =
    denormalize(data, 'onboardingAdmins', head(types.onboardingAdmin)) || {}

  return {
    isLoaded: state.isLoadedAdmin,
    isLoading: state.isLoadingAdmin,
    entity: { ...admins, ...onboardingAdmins },
  }
})

export const getAdminId = createSelector(getState, (state) =>
  head(Object.keys(state.admin)),
)
